import styled from "@emotion/styled";
import { Select } from "components/input/Select";
import { CustomPropFilter, FilterDefaults } from "features/custom-props/types";

export const BooleanFilter = ({ filter }: { filter: CustomPropFilter }) => {
  const { prop, onChange, value = "" } = filter;

  return (
    <Wrapper>
      <span>{prop.name}</span>
      <Select
        onChange={onChange}
        value={value}
        options={[
          { label: "All", value: "" },
          { label: "True", value: "true" },
          { label: "False", value: "false" },
          { label: "Not set", value: FilterDefaults.EMPTY },
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
