import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";

type Props = {
  value: boolean;
  label: string;
  onChange: (newValue: boolean) => void;
};

export const Switch = ({ label, value, onChange }: Props): JSX.Element => (
  <FormControlLabel
    control={
      <MuiSwitch
        value={value}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
    }
    label={label}
  />
);
