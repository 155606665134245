export const config = {
  apiUri: process.env.REACT_APP_API_URL ?? "http://localhost:8061",
  auth: {
    domain: String(process.env.REACT_APP_AUTH_DOMAIN),
    clientId: String(process.env.REACT_APP_AUTH_CLIENT_ID),
    audience: String(process.env.REACT_APP_AUTH_AUDIENCE),
    redirectUri:
      process.env.REACT_APP_AUTH0_REDIRECT_URI ?? window.location.origin,
  },
};
