import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export const VisibilityIcon = ({ visible }: { visible: boolean }) => (
  <>
    {visible === true && <RemoveRedEyeOutlinedIcon />}

    {visible === false && <VisibilityOffOutlinedIcon />}
  </>
);
