import { CustomPropInputSelectors } from "./components/sources/CustomPropSource";
import { SourceElement } from "./components/sources/SourceElement";
import { TaxonomyInputSelectors } from "./components/sources/TaxonomySource";
import { ScrapedTermAction } from "features/scraper";
import { CustomProp, CustomPropValue } from "features/custom-props";

export interface BasicCollection {
  id: number;
  title: string;
  coverImage: Image;
  taxonomies: Taxonomy[];
  customProps: CustomProp[];
  sources: Source[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Collection extends BasicCollection {}

export interface CollectionInput {
  title: string;
  coverImageId: number;
}

export interface CollectionIdInput {
  collectionId: number;
}

export interface Item {
  id: number;
  collectionId: string;
  title: string;
  coverImage: Image;
  collection: Collection;
  terms: ItemTerm[];
  customProps: CustomPropValue[];
  sources: ItemSource[];
  rank?: number;
  rankNote?: string;
  rankedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

type ItemSource = {
  id: number;
  url: string;
  sourceId: number;
  source: Source;
};

export interface ItemInput {
  title: string;
  coverImageId: number;
  customPropData: Record<string, string>;
  usedSources: {
    sourceId: number;
    url: string;
  }[];
  taxonomies?: SingleScrapeResult["taxonomies"];
  newTermChanges?: {
    taxonomyId: number;
    name: string;
    action: ScrapedTermAction;
  }[];
}

export interface ImageInput {
  url: string;
  meta: string;
}

type ImageCrop = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface ImageUpdate {
  crop?: ImageCrop;
}

export interface Image extends ImageInput {
  id: number;
  crop?: string;
  cloudinaryId?: string; // @deprecated
  cropped?: boolean; // @deprecated
}

export interface TaxonomyInput {
  name: string;
  useAvatar: boolean;
  avatarWidth?: number;
  avatarHeight?: number;
}

export interface Taxonomy extends TaxonomyInput {
  id: number;
  terms: Term[];
  customProps: CustomProp[];
}

export interface ItemTerm {
  id: number;
  termId: number;
  term: Term;
  item: Item;
}

export interface TermInput {
  name: string;
  avatar?: Image;
  avatarId?: number;
  taxonomyId: number;
}

export interface Term extends TermInput {
  id: number;
  hidden: boolean;
  activeFeed: boolean;
  items: ItemTerm[];
  taxonomy: Taxonomy;
  customProps: CustomPropValue[];
  sources: TermSource[];
}

type TermSource = {
  id: number;
  termId: number;
  source?: Source;
  sourceId: number;
  url: string;
};

export enum FilterType {
  PROP = "PROP",
}

export type FilterEntry = {
  type: FilterType;
  id: number;
  value: string;
};

export type Source = {
  id: number;
  name: string;
  config: SourceConfig;
};

export type SourceConfig = {
  basic: {
    name: string;
    scraper: string;
    waitFor?: string;
    queryUrl: string;
    multiResultSelector: string;
    multiResultItemSelector: string;
    singleResultSelector: string;
  };
  multiResultSelectors: {
    title: SourceElement;
    cover: SourceElement;
    source: SourceElement;
    nextPageSource: SourceElement;
  };
  singleResultSelectors: {
    title: SourceElement;
    cover: SourceElement;
  };
  customPropsSelectors: CustomPropInputSelectors;
  taxonomySelectors: TaxonomyInputSelectors;
};

export type ScrapeValue = {
  rawValue: string;
  value: string;
};

export type SingleScrapeResultAttributes = {
  title: ScrapeValue;
  cover: ScrapeValue;
};

export type SingleScrapeResult = {
  sourceId: number;
  type: "singleResult";
  queryUrl: string;
  responseUrl: string;
  attributes: { [key: string]: ScrapeValue };
  taxonomies: {
    parent: Taxonomy;
    results: {
      attributes: { [key: string]: ScrapeValue };
      matchedTerm: Term | null;
    }[];
  }[];
};

export type MultiScrapeResultAttributes = {
  title: ScrapeValue;
  cover: ScrapeValue;
  source: ScrapeValue;
};

export type MultiScrapeResult = {
  type: "multiResult";
  queryUrl: string;
  responseUrl: string;
  nextPageSource?: string;
  items: {
    scrapedItemResult: ScrapedItemResult;
    attributes: {
      title: ScrapeValue;
      cover: ScrapeValue;
      source: ScrapeValue;
    };
  }[];
};

export type ScrapedItemResult = {
  id: number;
  sourceId: number;
  url: string;
  state: ScrapedItemState;
};

export enum ScrapedItemState {
  LOADED = "LOADED",
  CHOSEN = "CHOSEN",
  DISMISSED = "DISMISSED",
}

export type ScrapeTargetTransformer = {
  type: string;
  options?: { [key: string]: string };
};
