import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CollectionContext } from "../providers/CollectionProvider";

export const useCollection = () => {
  const navigate = useNavigate();

  const { getCollection, setCollection, refetchCollection } =
    useContext(CollectionContext);

  const navigateToOverview = () => {
    const collection = getCollection();

    navigate(`/collections/${collection.id}`);
  };

  return {
    collection: getCollection(),
    setCollection,
    refetchCollection,
    navigateToOverview,
  };
};
