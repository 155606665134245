import styled from "@emotion/styled";
import { format, formatDistanceToNow } from "date-fns";

type Props = {
  value?: string;
};

export const DateView = ({ value }: Props) => {
  if (value == null) {
    return <Wrapper>(emtpy)</Wrapper>;
  }

  const date = new Date(Number(value) * 1000);

  return (
    <Wrapper>
      <p>{format(date, "dd MMMM yyyy")}</p>
      <p>
        <small>({formatDistanceToNow(date)} ago)</small>
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  background: lightgray;
`;
