import collectionReducerImport from "./collection-slice";

// Redux
export const collectionReducer = collectionReducerImport;
export {
  clearCollection,
  clearFilters,
  selectOverviewSettings,
  selectCustomPropFilterValues,
  selectSearchQuery,
  selectSortBy,
  setSearchQuery,
} from "./collection-slice";

// Components
export { CollectionSources } from "./components/collection-sources";
export { CollectionSideMenu } from "./components/collection-sidemenu";
