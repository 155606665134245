import styled from "@emotion/styled";
import { MultiScrapeResult } from "types";

export const MultiScrapeResultView = ({
  result,
  onSelect,
}: {
  result: MultiScrapeResult;
  onSelect: (item: { sourceUrl: string }) => void;
}) => {
  return (
    <Wrapper>
      {result.items.map((item) => (
        <MultiItem
          key={item.attributes.source.value}
          onClick={() => onSelect({ sourceUrl: item.attributes.source.value })}
        >
          <img
            src={item.attributes.cover.value}
            alt={item.attributes.title.value}
          />
          <h3>{item.attributes.title.value}</h3>
        </MultiItem>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
`;

const MultiItem = styled.article`
  cursor: pointer;

  img {
    width: 100%;
  }

  h3 {
    word-wrap: break-word;
  }
`;
