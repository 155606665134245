import { CustomPropFilter, CustomPropType } from "../types";
import { BooleanFilter } from "./prop-filter-inputs/boolean-filter";
import { DateFilter } from "./prop-filter-inputs/date-filter";
import { TextFilter } from "./prop-filter-inputs/text-filter";
import { UrlFilter } from "./prop-filter-inputs/url-filter";

export const CustomPropFilterInput = ({
  filter,
}: {
  filter: CustomPropFilter;
}): JSX.Element => {
  if (filter.prop.type === CustomPropType.BOOLEAN) {
    return <BooleanFilter filter={filter} />;
  }

  if (filter.prop.type === CustomPropType.URL) {
    return <UrlFilter filter={filter} />;
  }

  if (filter.prop.type === CustomPropType.DATE) {
    return <DateFilter filter={filter} />;
  }

  if (filter.prop.type === CustomPropType.TEXT) {
    return <TextFilter filter={filter} />;
  }

  return <>Unsupported prop type: {filter.prop.type}</>;
};
