import { TextInput } from "../../input/TextInput";

type Props = {
  onChange: (newValue: string) => void;
  value: string;
  label?: string;
};

export const UrlForm = ({
  onChange,
  value = "",
  label = "Url",
}: Props): JSX.Element => {
  return <TextInput label={label} onChange={onChange} value={value} />;
};
