import { Select } from "components/input/Select";
import { useAppDispatch, useAppSelector } from "redux-hooks";
import { sortOptions, selectSortBy, setSortBy } from "../collection-slice";

export const CollectionSorting = () => {
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(selectSortBy);

  return (
    <>
      <Select
        value={String(sortBy)}
        onChange={(i) => {
          dispatch(setSortBy(i));
        }}
        options={sortOptions.map((sort) => ({
          label: String(sort),
          value: String(sort),
        }))}
      />
    </>
  );
};
