import { TextInput } from "../../input/TextInput";

type Props = {
  onChange: (newValue: string) => void;
  value: string;
  label?: string;
};

export const TextForm = ({
  onChange,
  value = "",
  label = "Text",
}: Props): JSX.Element => {
  return (
    <TextInput
      label={label}
      onChange={onChange}
      value={value}
      type="text"
      autoFocus
    />
  );
};
