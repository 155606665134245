import { useCallback, useEffect, useState } from "react";
import { useApi } from "./useApi";
import objectPath from "object-path";

export type UserSettings = {
  imageKit_endpointUrl?: string;
  imageKit_privateKey?: string;
  imageKit_folder?: string;
};

enum Status {
  INITIALIZING = "initializing",
  LOADING = "loading",
  INITIALIZED = "initialized",
}

const getDefaultSettings = (): UserSettings => ({
  imageKit_endpointUrl: "",
  imageKit_folder: "",
  imageKit_privateKey: "",
});

export const useSettings = () => {
  const [status, setStatus] = useState<Status>(Status.INITIALIZING);
  const [settings, setSettings] = useState<UserSettings>(getDefaultSettings());
  const { get, post } = useApi();

  const saveSettings = useCallback(
    (newSettings) => {
      localStorage.setItem("settings", JSON.stringify(newSettings));
      setSettings(newSettings);
    },
    [setSettings]
  );

  const getSettings = useCallback(async (): Promise<UserSettings> => {
    const localSettings = localStorage.getItem("settings");

    if (localSettings != null) {
      return JSON.parse(localSettings);
    }

    const { data } = await get<UserSettings>("/settings");
    if (data == null) return getDefaultSettings();
    saveSettings(data);

    return data;
  }, [get, saveSettings]);

  useEffect(() => {
    if (status !== Status.INITIALIZING) return;
    setStatus(Status.LOADING);

    const loadSettings = async () => {
      const settings = await getSettings();

      setSettings(settings);
      setStatus(Status.INITIALIZED);
    };

    loadSettings();
  }, [get, getSettings, saveSettings, status]);

  const updateSetting = async (path: string, newValue: string) => {
    let newSettings = { ...settings };
    objectPath.set(newSettings, path, newValue);

    const { data } = await post<UserSettings, UserSettings>(
      "/settings",
      newSettings
    );

    saveSettings(data);
  };

  return {
    loading: status !== Status.INITIALIZED,
    settings,
    updateSetting,
  };
};
