import { format } from "date-fns";
import { TextInput } from "../../input/TextInput";

type Props = {
  onChange: (newValue: string) => void;
  value: string;
  label?: string;
};

export const DateForm = ({
  onChange,
  value = "",
  label = "Date",
}: Props): JSX.Element => {
  const handleChange = (newValue: string) => {
    onChange(format(new Date(newValue), "t"));
  };

  return (
    <TextInput
      label={label + " (mm/dd/yyyy)"}
      onChange={handleChange}
      value={format(new Date(Number(value) * 1000), "yyyy-MM-dd")}
      type="date"
      autoFocus
    />
  );
};
