import styled from "@emotion/styled";
import { useSettings } from "../hooks/useSettings";
import { Image } from "../types";
import { IKImage, IKContext } from "imagekitio-react";

type Props = {
  crop?: boolean;
  image?: Image;
  width?: number;
  height?: number;
};

export const CloudImage = ({
  crop = true,
  image,
  width,
  height,
}: Props): JSX.Element => {
  const { settings, loading } = useSettings();

  if (loading) return <></>;
  if (image == null) return <></>;

  const meta = image.meta ? JSON.parse(image.meta) : {};
  const cropData = image.crop ? JSON.parse(image.crop) : {};
  const cropTransform = crop
    ? {
        ...(cropData.x != null ? { x: cropData.x } : {}),
        ...(cropData.y != null ? { y: cropData.y } : {}),
        ...(cropData.width != null ? { width: cropData.width } : {}),
        ...(cropData.height != null ? { height: cropData.height } : {}),
        cropMode: "extract",
      }
    : {};

  if (
    settings.imageKit_endpointUrl == null ||
    settings.imageKit_endpointUrl === ""
  ) {
    return <></>;
  }

  return (
    <IKContext urlEndpoint={settings.imageKit_endpointUrl}>
      <ImageWrapper>
        <IKImage
          path={meta.filePath}
          transformation={[
            cropTransform,
            {
              ...(height != null ? { height } : {}),
              ...(width != null ? { width } : {}),
            },
          ]}
        />
      </ImageWrapper>
    </IKContext>
  );
};

const ImageWrapper = styled.div`
  img {
    max-width: 100%;
  }
`;
