import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import { useSettings } from "../../hooks/useSettings";
import { uploadToImageKit } from "../../services/uploadImage";
import { TextInput } from "./TextInput";

type ImageData = {
  url: string;
  meta: string;
};

type Props = {
  onUpload: (imageData: ImageData) => void;
  uriInput?: string;
};

enum State {
  ready,
  uploading,
}

export const ImageKitUploader = ({ onUpload, uriInput = "" }: Props) => {
  const [state, set_state] = useState<State>(State.ready);
  const [uploadedUri, set_uploadedUri] = useState("");
  const [webUrl, setWebUrl] = useState(uriInput);
  const { settings } = useSettings();

  const uploadFromUrl = useCallback(
    async (urlToUpload: string) => {
      if (state === State.uploading) return;
      if (uploadedUri !== "" && uploadedUri === uriInput) return;
      set_state(State.uploading);

      try {
        const { url, meta } = await uploadToImageKit({
          url: urlToUpload,
          settings,
        });

        onUpload({ url, meta });
      } catch (e) {
        console.log({ e });
      } finally {
        set_state(State.ready);
      }
    },
    [onUpload, settings, state, uploadedUri, uriInput]
  );

  useEffect(() => {
    if (uriInput === "") return;

    set_uploadedUri(uriInput);
    uploadFromUrl(uriInput);
  }, [uploadFromUrl, uriInput]);

  return (
    <>
      <TextInput
        value={webUrl}
        autoFocus
        label="File url"
        onChange={setWebUrl}
      />

      <Button onClick={() => uploadFromUrl(webUrl)}>Upload</Button>
    </>
  );
};
