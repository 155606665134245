import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { CollectionHeader } from "../components/CollectionHeader";
import { Button } from "../components/input/Button";
import { isJsonString } from "../helpers";
import { useFeed } from "../hooks/useFeed";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { FeedsMenu } from "../components/sources/FeedsMenu";
import { Item, ItemInput, SingleScrapeResult } from "../types";
import { uploadImage } from "../services/uploadImage";
import { useSettings } from "../hooks/useSettings";
import { useApi } from "../hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { ValueForm } from "../components/props/ValueForm";
import { SingleScrapeResultView } from "features/scraper";
import { Switch } from "../components/input/Switch";

enum State {
  idle,
  saving,
  dismissing,
}

const FeedPage = () => {
  const { settings } = useSettings();
  const { collection } = useCollection();
  const api = useApi();
  const { sources: sourcesParam } = useParams<"sources">();
  const {
    item,
    skipItem,
    done,
    doneWithCurrentPages,
    sources,
    sourcesToLoad,
    sourcesDone,
    toggleSource,
    dismissItem,
    addPage,
    maxPages,
  } = useFeed({
    initialSources: isJsonString(sourcesParam)
      ? JSON.parse(sourcesParam as string)
      : [],
  });
  const [state, setState] = useState<State>(State.idle);
  const [customPropData, setCustomPropData] = useState<Record<string, string>>(
    {}
  );
  const [autoDismiss, setAutoDismiss] = useState(false);

  const toggleFeed = (source) => {
    toggleSource({ source });
  };

  const dismiss = useCallback(async () => {
    if (item == null || state === State.dismissing) return;

    setState(State.dismissing);
    await dismissItem();
    setCustomPropData({});

    if (autoDismiss) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setState(State.idle);
    } else {
      setState(State.idle);
    }
  }, [autoDismiss, dismissItem, item, state]);

  useEffect(() => {
    if (item == null || autoDismiss === false || state !== State.idle) return;

    dismiss();
  }, [autoDismiss, dismiss, item, state]);

  const renderHeader = () => {
    return (
      <>
        <CollectionHeader
          secondaryMenus={[
            {
              element: (
                <FeedsMenu
                  activeFeeds={sources.map((s) => s.config)}
                  toggleFeedSource={toggleFeed}
                />
              ),
              buttonIcon: <FactCheckOutlinedIcon />,
            },
          ]}
        />
        <AutoDismissToggle>
          <Switch
            label="auto dismiss"
            onChange={setAutoDismiss}
            value={autoDismiss}
          />
        </AutoDismissToggle>
      </>
    );
  };

  const saveItem = async () => {
    if (item == null) return;
    setState(State.saving);

    const saveData: ItemInput = {
      usedSources: [{ sourceId: item.sourceId, url: item.responseUrl }],
      title: item.attributes.title.value,
      coverImageId: (
        await uploadImage({
          url: item.attributes.cover.value,
          settings,
          api,
        })
      ).id,
      customPropData: {
        ...customPropData,
        ...Object.entries(item.attributes)
          .filter(([key]) => key.startsWith("customProp-"))
          .reduce((prev, [key, scrapeValue]) => {
            prev[key.replace("customProp-", "")] = scrapeValue.value;
            return prev;
          }, {} as ItemInput["customPropData"]),
      },
      taxonomies: item.taxonomies,
    };

    await api.post<Item, ItemInput & { collectionId: number }>(`/items`, {
      ...saveData,
      collectionId: Number(collection.id),
    });

    setCustomPropData({});
    setState(State.idle);
    skipItem();
  };

  const loadingSource = sourcesToLoad[0];

  if (item == null && done === false && doneWithCurrentPages === false) {
    return (
      <Wrapper>
        {renderHeader()}

        <p>Loading next item...</p>
        <p>
          Source {sourcesDone + 1}/{sources.length}
        </p>
        <p>{loadingSource != null && loadingSource.name}</p>
      </Wrapper>
    );
  }

  if (item == null && done === true) {
    return (
      <Wrapper>
        {renderHeader()}

        <p>No more items left in queue.</p>
      </Wrapper>
    );
  }

  if (item == null && done === false && doneWithCurrentPages === true) {
    return (
      <Wrapper>
        {renderHeader()}

        <p>
          No more items for the current page count.
          <br />
          Max pages: {maxPages}
        </p>

        <ActionsWrapper>
          <Button primary onClick={addPage}>
            Load next page
          </Button>
        </ActionsWrapper>
      </Wrapper>
    );
  }

  const scrapedCustomPropIds = Object.entries(item?.attributes ?? {})
    .filter(([key]) => key.startsWith("customProp-"))
    .reduce((prev, [key]) => {
      prev.push(Number(key.replace("customProp-", "")));
      return prev;
    }, [] as number[]);
  const nonScrapedCustomProps = collection.customProps.filter(
    (cp) => scrapedCustomPropIds.includes(cp.id) === false
  );

  return (
    <Wrapper>
      {renderHeader()}

      <SingleScrapeResultView result={item as SingleScrapeResult} />

      <CustomPropsWrapper>
        {nonScrapedCustomProps.map((customProp) => {
          return (
            <ValueForm
              key={customProp.id}
              prop={customProp}
              onChange={(newValue: string) => {
                setCustomPropData({
                  ...customPropData,
                  [customProp.id]: newValue,
                });
              }}
              value={(customPropData[customProp.id] as string) || ""}
              label={customProp.name}
            />
          );
        })}
      </CustomPropsWrapper>

      <ActionsWrapper>
        <Button
          size="large"
          primary
          color="primary"
          onClick={() => saveItem()}
          disabled={state !== State.idle}
        >
          {state === State.saving ? "Adding..." : "Add"}
        </Button>

        <Button
          size="large"
          onClick={() => {
            setCustomPropData({});
            skipItem();
          }}
          disabled={state !== State.idle}
        >
          Skip
        </Button>

        <Button
          size="large"
          primary
          color="red"
          onClick={dismiss}
          disabled={state !== State.idle}
        >
          Dismiss
        </Button>
      </ActionsWrapper>
    </Wrapper>
  );
};

export default FeedPage;

const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 120px;
`;

const CustomPropsWrapper = styled.div`
  padding: 0 12px;
  margin: 12px 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background: white;
  border-top: 3px solid black;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const AutoDismissToggle = styled.div`
  margin-left: 24px;
  margin-bottom: 24px;
`;
