import MuiButton from "@mui/material/Button";
import { ReactElement } from "react";

type Props = {
  children: ReactElement | String;
  color?: "primary" | "blue" | "red" | "error";
  onClick?: Function;
  primary?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  size?: "small" | "medium" | "large";
};

export const Button = ({
  children,
  color = "primary",
  onClick,
  primary = false,
  type = "button",
  disabled = false,
  size = "medium",
}: Props): JSX.Element => {
  const matchedColor = (() => {
    const mappedColors = {
      blue: "primary",
      red: "error",
    };

    return mappedColors[color] ?? color;
  })();

  return (
    <MuiButton
      color={matchedColor}
      variant={primary ? "contained" : "text"}
      onClick={() => typeof onClick === "function" && onClick()}
      type={type}
      disabled={disabled}
      size={size}
    >
      {children}
    </MuiButton>
  );
};
