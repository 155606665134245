import { clone } from "lodash";
import { createContext, useState } from "react";
import { useApi } from "../hooks/useApi";
import { BasicCollection } from "../types";

type CollectionContextTypes = {
  getCollection: () => BasicCollection;
  setCollection: (collection: BasicCollection) => void;
  refetchCollection: () => Promise<BasicCollection>;
};

export const CollectionContext = createContext<CollectionContextTypes>({
  getCollection: () => ({} as BasicCollection),
  setCollection: () => {},
  refetchCollection: async () => ({} as BasicCollection),
});

type Props = {
  children?: React.ReactElement;
};

export const CollectionProvider = ({ children }: Props): JSX.Element => {
  const { get } = useApi();
  const [collection, setCollection] = useState<BasicCollection | null>(null);

  const getCurrentCollection = (): BasicCollection => {
    if (collection != null) return collection;

    const localCollectionString = localStorage.getItem(
      "currentCollection"
    ) as string;
    const localCollection = JSON.parse(
      localCollectionString
    ) as BasicCollection;

    setTimeout(() => setCurrentCollection(localCollection), 0);
    return localCollection;
  };

  const setCurrentCollection = (collection: BasicCollection) => {
    let transformedCollection = clone(collection);
    if (transformedCollection?.sources != null) {
      transformedCollection.sources = transformedCollection.sources.map(
        (source) => {
          if (typeof source.config === "string") {
            return {
              ...source,
              config: JSON.parse(source.config as unknown as string),
            };
          }

          return source;
        }
      );
    }

    localStorage.setItem(
      "currentCollection",
      JSON.stringify(transformedCollection)
    );
    setCollection(transformedCollection);
  };

  const refetch = async (): Promise<BasicCollection> => {
    const currentCollection = getCurrentCollection();

    const { data: newCollection } = await get<BasicCollection>(
      `/collections/${currentCollection.id}`
    );

    setTimeout(() => setCurrentCollection(newCollection), 0);

    return newCollection;
  };

  return (
    <CollectionContext.Provider
      value={{
        getCollection: getCurrentCollection,
        setCollection: setCurrentCollection,
        refetchCollection: refetch,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
