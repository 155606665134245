import { Label } from "../../input/Label";
import { Select } from "../../input/Select";

type Props = {
  onChange: (newValue: string) => void;
  value: string;
  label?: string;
};

export const BooleanForm = ({
  onChange,
  value = "",
  label = "Boolean",
}: Props): JSX.Element => {
  return (
    <>
      <Label>{label}</Label>
      <Select
        value={value}
        onChange={onChange}
        options={[
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ]}
      />
    </>
  );
};
