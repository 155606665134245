import { CloudImage } from "./CloudImage";
import {
  Header,
  HeaderButton,
  SecondaryButton,
  SecondaryMenuOptions,
} from "./Header";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import { useCollection } from "../hooks/useCollection";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import { CustomProp } from "features/custom-props";

type Props = {
  buttons?: HeaderButton[];
  propsToFilter?: CustomProp[];
  secondaryButtons?: SecondaryButton[];
  secondaryMenus?: SecondaryMenuOptions[];
};

export const CollectionHeader = ({
  buttons = [],
  secondaryButtons = [],
  secondaryMenus = [],
}: Props): JSX.Element => {
  const { collection } = useCollection();

  const taxonomyMenuItems = (collection.taxonomies ?? [])
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((taxonomy) => ({
      text: taxonomy.name,
      linkTo: `/collections/${collection.id}/taxonomies/${taxonomy.id}`,
      icon: <CategoryOutlinedIcon />,
    }));

  let allButtons = [...buttons];
  if (buttons.length > 0) {
    allButtons.push({ text: "DIVIDER-buttons", onClick: () => {} });
  }

  allButtons.push(
    ...[
      {
        icon: <AddOutlinedIcon />,
        linkTo: `/collections/${collection.id}/new`,
        text: "Add item",
      },
      {
        icon: <DynamicFeedOutlinedIcon />,
        linkTo: `/collections/${collection.id}/feeds`,
        text: "Feeds",
      },
      {
        icon: <StarHalfOutlinedIcon />,
        linkTo: `/collections/${collection.id}/rank`,
        text: "Rank",
      },
      {
        linkTo: `/collections/${collection.id}/config`,
        text: "Configure",
        icon: <DisplaySettingsOutlinedIcon />,
      },
    ]
  );

  if (taxonomyMenuItems.length > 0) {
    allButtons.push({ text: "DIVIDER-taxonomy", onClick: () => {} });
  }
  allButtons.push(...taxonomyMenuItems);

  return (
    <Header
      title={collection.title}
      titleLinkTo={`/collections/${collection.id}`}
      buttons={allButtons}
      card={<CloudImage image={collection.coverImage} width={240} />}
      secondaryButtons={secondaryButtons}
      secondaryMenus={secondaryMenus}
    />
  );
};
