import styled from "@emotion/styled";
import { TextInput } from "../../input/TextInput";

type Props = {
  value?: string;
};

export const UrlView = ({ value }: Props) => {
  if (value == null) {
    return <Wrapper>(emtpy)</Wrapper>;
  }

  return (
    <Wrapper>
      {value.startsWith("http") && (
        <Link href={value} target="_blank">
          {new URL(value).hostname.replace("www.", "")}
        </Link>
      )}

      {value.startsWith("http") === false && (
        <TextInput value={value} disabled={true} copy={true} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  background: lightgray;
`;
const Link = styled.a`
  flex: 1;
`;
