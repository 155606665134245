import styled from "@emotion/styled";
import { Switch } from "components/input/Switch";
import { useAppDispatch, useAppSelector } from "redux-hooks";
import {
  selectOverviewSettings,
  setOverviewSettings,
} from "../collection-slice";

export const CollectionOverviewSettings = () => {
  const dispatch = useAppDispatch();
  const overviewSettings = useAppSelector(selectOverviewSettings);

  return (
    <Wrapper>
      <Switch
        label="Show titles"
        value={overviewSettings.showTitles}
        onChange={(newValue) =>
          dispatch(setOverviewSettings({ showTitles: newValue }))
        }
      />

      <Switch
        label="Show rank"
        value={overviewSettings.showRank}
        onChange={(newValue) =>
          dispatch(setOverviewSettings({ showRank: newValue }))
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
