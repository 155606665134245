import { Button } from "components/input/Button";
import { useState } from "react";
import styled from "@emotion/styled";
import { AddSource } from "components/sources/AddSource";
import { useCollection } from "hooks/useCollection";
import { Source } from "types";
import { useApi } from "hooks/useApi";
import { SourceForm } from "components/sources/SourceForm";
import { Scraper } from "components/sources/Scraper";

enum State {
  overview,
  add,
  edit,
  test,
}

export const CollectionSources = (): JSX.Element => {
  const { collection, refetchCollection } = useCollection();
  const { del, post } = useApi();
  const [state, setState] = useState<State>(State.overview);
  const [current, setCurrent] = useState<Source | null>(null);

  const handleSourceEdit = async (newConfig) => {
    if (current?.id == null) return;

    await post(`/sources/${current.id}`, {
      name: newConfig.basic.name,
      config: newConfig,
    });

    await refetchCollection();
    setCurrent(null);
    setState(State.overview);
  };

  return (
    <Wrapper>
      {state === State.overview && (
        <>
          <Button onClick={() => setState(State.add)}>Add</Button>
          <Sources>
            {collection.sources.map((source) => (
              <article key={source.id}>
                <span>{source.name}</span>
                <div>
                  <Button
                    onClick={() => {
                      setCurrent(source);
                      setState(State.test);
                    }}
                  >
                    Test
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrent(source);
                      setState(State.edit);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={async () => {
                      if (window.confirm("Do you want to delete?") !== true)
                        return;

                      await del(`/sources/${source.id}`);
                      await refetchCollection();
                    }}
                    color="red"
                  >
                    Delete
                  </Button>
                </div>
              </article>
            ))}
          </Sources>
        </>
      )}

      {state === State.add && (
        <>
          <Button onClick={() => setState(State.overview)}>Cancel</Button>
          <AddSource onComplete={() => setState(State.overview)} />
        </>
      )}

      {state === State.test && current != null && (
        <>
          <Button onClick={() => setState(State.overview)}>Cancel</Button>
          <Scraper source={current} debug={true} />
        </>
      )}

      {state === State.edit && current != null && (
        <>
          <Button
            onClick={() => {
              setCurrent(null);
              setState(State.overview);
            }}
          >
            Cancel
          </Button>
          <SourceForm onSave={handleSourceEdit} source={current} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Sources = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;

  > article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: lightgray;
    padding: 12px;
  }
`;
