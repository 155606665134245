import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useRef } from "react";

type Props = {
  label?: string;
  onChange?: (value: string) => void;
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  copy?: boolean;
  type?: string;
  placeholder?: string;
  multiline?: boolean;
};

export const TextInput = ({
  label,
  onChange = () => {},
  value,
  autoFocus = false,
  disabled = false,
  copy = false,
  type = "text",
  placeholder,
  multiline = false,
}: Props): JSX.Element => {
  const inputRef = useRef(null);

  const handleCopy = () => {
    const element = inputRef.current as unknown as HTMLElement;
    const input = element.querySelector("input") as unknown as HTMLInputElement;

    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(input.value);
  };

  return (
    <Wrapper>
      <TextField
        label={label}
        variant="standard"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        fullWidth
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        multiline={multiline}
      />
      {copy && <Button onClick={handleCopy}>Copy</Button>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;
