import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useScript } from "./hooks/useScript";
import { RoutesList } from "./router/RoutesList";
import "react-toastify/dist/ReactToastify.css";
import { CollectionProvider } from "./providers/CollectionProvider";

function App() {
  useScript("https://upload-widget.cloudinary.com/global/all.js");
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <p>Loading app...</p>;
  }

  return (
    <CollectionProvider>
      <Router>
        <RoutesList />
        <ToastContainer position="bottom-left" closeOnClick pauseOnHover />
      </Router>
    </CollectionProvider>
  );
}

export default App;
