import styled from "@emotion/styled";
import {
  MultiScrapeResult,
  MultiScrapeResultAttributes,
  SingleScrapeResult,
  SingleScrapeResultAttributes,
} from "../../types";
import { SingleScrapeResultView } from "features/scraper";

export const ScrapeResult = ({
  result,
  onSelectMultiResult,
  onSelectSingleResult,
  hideTaxonomies = false,
}: {
  result: SingleScrapeResult | MultiScrapeResult;
  onSelectMultiResult: (item: MultiScrapeResultAttributes) => void;
  onSelectSingleResult: (item: SingleScrapeResultAttributes) => void;
  hideTaxonomies?: boolean;
}) => {
  const handleMultiItemClick = (item: any) => {
    onSelectMultiResult(item);
  };

  if (result.type === "singleResult") {
    return (
      <SingleWrapper>
        <SingleScrapeResultView
          result={result as SingleScrapeResult}
          hideTaxonomies={hideTaxonomies}
        />
      </SingleWrapper>
    );
  }

  return (
    <MultiWrapper>
      {result.items.map((item) => (
        <MultiItem
          key={item.attributes.source.value}
          onClick={() => handleMultiItemClick(item.attributes)}
        >
          <img
            src={item.attributes.cover.value}
            alt={item.attributes.title.value}
          />
          <h3>{item.attributes.title.value}</h3>
        </MultiItem>
      ))}
    </MultiWrapper>
  );
};

const SingleWrapper = styled.section`
  margin-bottom: 24px;
`;

const MultiWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
`;

const MultiItem = styled.article`
  cursor: pointer;

  img {
    width: 100%;
  }

  h3 {
    word-wrap: break-word;
  }
`;
