import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropFilterValue } from "features/custom-props";
import { RootState } from "store";

interface TaxonomyState {
  id: number;
  customPropFilterValues?: PropFilterValue[];
  searchQuery?: string;
}

const initialState: {
  taxonomies: TaxonomyState[];
} = {
  taxonomies: [],
};

const getTaxonomyById = (
  taxonomies: TaxonomyState[],
  id: number
): {
  match: TaxonomyState | undefined;
  rest: TaxonomyState[];
} => {
  return {
    match: taxonomies.find((t) => t.id === id),
    rest: taxonomies.filter((t) => t.id !== id),
  };
};

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {
    clearFilters: (state, action: PayloadAction<number>) => {
      const { rest } = getTaxonomyById(state.taxonomies, action.payload);

      state.taxonomies = [...rest];
    },
    setCustomPropFilterValue: (
      state,
      action: PayloadAction<{
        taxonomyId: number;
        propFilterValue: PropFilterValue;
      }>
    ) => {
      const { match: taxonomy, rest } = getTaxonomyById(
        state.taxonomies,
        action.payload.taxonomyId
      );

      if (taxonomy == null) return;

      const otherValues = (taxonomy.customPropFilterValues ?? []).filter(
        (pf) => pf.id !== action.payload.propFilterValue.id
      );

      if (action.payload.propFilterValue.value.trim() === "") {
        state.taxonomies = [
          ...rest,
          { ...taxonomy, customPropFilterValues: otherValues },
        ];
      } else {
        state.taxonomies = [
          ...rest,
          {
            ...taxonomy,
            customPropFilterValues: [
              ...otherValues,
              action.payload.propFilterValue,
            ],
          },
        ];
      }
    },
    setSearchQuery: (
      state,
      action: PayloadAction<{ taxonomyId: number; newQuery: string }>
    ) => {
      const { match, rest } = getTaxonomyById(
        state.taxonomies,
        action.payload.taxonomyId
      );

      state.taxonomies = [
        ...rest,
        {
          ...(match != null ? match : { id: action.payload.taxonomyId }),
          searchQuery: action.payload.newQuery,
        },
      ];
    },
  },
});

// Reducers
export const { clearFilters, setCustomPropFilterValue, setSearchQuery } =
  taxonomySlice.actions;

// Selectors
export const selectSearchQuery = (taxonomyId: number) => (state: RootState) => {
  const { match } = getTaxonomyById(state.taxonomy.taxonomies, taxonomyId);
  return match?.searchQuery ?? "";
};

export const selectCustomPropFilterValue =
  (taxonomyId: number) => (state: RootState) => {
    const { match } = getTaxonomyById(state.taxonomy.taxonomies, taxonomyId);
    return match?.customPropFilterValues ?? [];
  };

export default taxonomySlice.reducer;
