import { configureStore } from "@reduxjs/toolkit";
import { collectionReducer } from "features/collection";
import { taxonomyReducer } from "features/taxonomy";

export const store = configureStore({
  reducer: { collection: collectionReducer, taxonomy: taxonomyReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
