import { useState } from "react";
import { Button } from "../input/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import { FormBuilder } from "../input/FormBuilder";
import { SourceInput } from "./SourceInput";
import { SourceElement } from "./SourceElement";
import { Source, SourceConfig } from "../../types";
import { useCollection } from "../../hooks/useCollection";
import {
  CustomPropInputSelectors,
  CustomPropsSource,
} from "./CustomPropSource";
import { TaxonomyInputSelectors, TaxonomySource } from "./TaxonomySource";

enum Scrapers {
  cloudscraper = "cloudscraper",
  puppeteer = "puppeteer",
  axios = "axios",
}

enum Tabs {
  basic,
  multiple,
  single,
}

export const SourceForm = ({
  source,
  onSave,
}: {
  source?: Source;
  onSave: (config: SourceConfig) => void;
}) => {
  const { collection } = useCollection();
  const [expanded, setExpanded] = useState<Tabs | false>(Tabs.single);
  const [basic, setBasic] = useState<any>(source?.config.basic ?? {});
  const [multiResultSelectors, setMultiResultSelectors] = useState<any>(
    source?.config.multiResultSelectors ?? {}
  );
  const [singleResultSelectors, setSingleResultSelectors] = useState<any>(
    source?.config.singleResultSelectors ?? {}
  );
  const [customPropsSelectors, setCustomPropsSelectors] =
    useState<CustomPropInputSelectors>(
      source?.config.customPropsSelectors ?? {}
    );

  const [taxonomySelectors, setTaxonomySelectors] =
    useState<TaxonomyInputSelectors>(source?.config.taxonomySelectors ?? {});

  const handleAccordionClick = (accordionId) => {
    setExpanded(expanded === accordionId ? false : accordionId);
  };

  const handleSourceChange = (
    source: SourceElement,
    setter: Function,
    options?: { key?: string }
  ) => {
    setter((prev) => ({ ...prev, [options?.key ?? source.name]: source }));
  };

  const handleSave = () => {
    onSave({
      basic,
      multiResultSelectors,
      singleResultSelectors,
      customPropsSelectors,
      taxonomySelectors,
    });
  };

  return (
    <>
      <Accordion
        expanded={expanded === Tabs.basic}
        onChange={() => handleAccordionClick(Tabs.basic)}
        sx={{ width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Basic ({basic.name})
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormBuilder
              initialState={basic}
              form={[
                { name: "name" },
                {
                  name: "scraper",
                  type: "select",
                  options: Object.values(Scrapers).map((s) => ({
                    label: s,
                    value: s,
                  })),
                  default: Scrapers.axios,
                },
                { name: "waitFor" },
                { name: "queryUrl" },
                { name: "multiResultSelector" },
                { name: "multiResultItemSelector" },
                { name: "singleResultSelector" },
              ]}
              onChange={setBasic}
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === Tabs.multiple}
        onChange={() => handleAccordionClick(Tabs.multiple)}
        sx={{ width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Multiple results
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {["title", "cover", "source", "nextPageSource"].map((input) => (
              <SourceInputWrapper key={input}>
                <SourceInput
                  source={
                    multiResultSelectors[input] ?? new SourceElement(input)
                  }
                  onChange={(source) =>
                    handleSourceChange(source, setMultiResultSelectors)
                  }
                />
              </SourceInputWrapper>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === Tabs.single}
        onChange={() => handleAccordionClick(Tabs.single)}
        sx={{ width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Single result
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {["title", "cover"].map((input) => (
              <SourceInputWrapper key={input}>
                <SourceInput
                  source={
                    singleResultSelectors[input] ?? new SourceElement(input)
                  }
                  onChange={(source) =>
                    handleSourceChange(source, setSingleResultSelectors)
                  }
                />
              </SourceInputWrapper>
            ))}

            <p style={{ marginTop: 36 }}>Custom props</p>
            {collection.customProps.map((c) => (
              <SourceInputWrapper key={c.id}>
                <CustomPropsSource
                  name={c.name}
                  keyName={`customProp-${c.id}`}
                  selectors={customPropsSelectors}
                  onChange={setCustomPropsSelectors}
                />
              </SourceInputWrapper>
            ))}

            <p style={{ marginTop: 36 }}>Taxonomies</p>
            {collection.taxonomies.map((t) => (
              <SourceInputWrapper key={t.id}>
                <TaxonomySource
                  name={t.name}
                  keyName={`taxonomy-${t.id}`}
                  selectors={taxonomySelectors}
                  onChange={setTaxonomySelectors}
                />
              </SourceInputWrapper>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Button onClick={handleSave} primary>
        Save
      </Button>
    </>
  );
};

const SourceInputWrapper = styled.div`
  padding: 12px;
  border: 1px solid black;
`;

const FormGroup = styled.div`
  > * {
    margin: 12px 0;
  }
`;
