import styled from "@emotion/styled";
import React from "react";
import { CollectionFilters } from "./collection-filters";
import { CollectionOverviewSettings } from "./collection-overview-settings";
import { CollectionSorting } from "./collection-sorting";

export const CollectionSideMenu = () => {
  const sections: { title: string; component: React.ReactElement }[] = [
    { title: "Sorting", component: <CollectionSorting /> },
    { title: "Filters", component: <CollectionFilters /> },
    { title: "Settings", component: <CollectionOverviewSettings /> },
  ];

  return (
    <Wrapper>
      {sections.map(({ title, component }) => (
        <Section key={title}>
          <SectionTitle>{title}</SectionTitle>
          {component}
        </Section>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Section = styled.section`
  padding-left: 20px;
  border-left: 3px solid gray;
  margin-top: 42px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const SectionTitle = styled.h3`
  font-weight: normal;
`;

export const SectionSubTitle = styled.h4`
  font-weight: 500;
`;
