import styled from "@emotion/styled";
import { Button } from "components/input/Button";
import { useCollection } from "../../hooks/useCollection";
import { FeedSource } from "../../hooks/useFeed";
import { useResource } from "../../hooks/useResource";
import { Taxonomy } from "../../types";
import { Switch } from "../input/Switch";

export const FeedsMenu = ({
  activeFeeds,
  toggleFeedSource,
}: {
  activeFeeds: FeedSource["config"][];
  toggleFeedSource: (source: FeedSource["config"]) => void;
}) => {
  const { collection } = useCollection();
  const { resource: taxonomies } = useResource<Taxonomy[]>({
    url: `/taxonomies`,
    queryParams: { collectionId: collection.id },
  });

  if (taxonomies == null) {
    return <p>No taxonomies found.</p>;
  }

  return (
    <Wrapper>
      {taxonomies
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((t) => {
          const termsWithSource = t.terms
            .filter((t) => t.activeFeed)
            .filter((t) => t.sources.length > 0 && t.hidden === false)
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

          const availableSources = termsWithSource.reduce((prev, term) => {
            term.sources.forEach((s) => (prev[s.source!.id] = s.source!.name));
            return prev;
          }, {} as Record<number, string>);

          return (
            <TaxonomyWrapper key={t.id}>
              <h2>{t.name}</h2>

              {Object.entries(availableSources).length > 0 &&
                Object.entries(availableSources).map(([id, name]) => (
                  <Button
                    key={id}
                    onClick={() => {
                      termsWithSource.forEach((t) => {
                        const matchedSource = t.sources.find(
                          (s) => Number(s.sourceId) === Number(id)
                        );
                        if (matchedSource == null) return;

                        toggleFeedSource({
                          sourceId: matchedSource.sourceId,
                          url: matchedSource.url,
                          name: `${t.name} - ${name}`,
                        });
                      });
                    }}
                  >
                    <span>{name}</span>
                  </Button>
                ))}

              {termsWithSource.length === 0 && (
                <p>No terms with a source found.</p>
              )}

              {termsWithSource.map((t) => {
                const sources = t.sources.map((s) => ({
                  id: s.id,
                  name: s.source!.name,
                  sourceId: s.sourceId,
                  url: s.url,
                }));

                return (
                  <SelectableTerm key={t.id}>
                    <h3>{t.name}</h3>

                    {sources.map((s) => (
                      <div key={s.id}>
                        <Switch
                          label={s.name}
                          onChange={() =>
                            toggleFeedSource({
                              sourceId: s.sourceId,
                              url: s.url,
                              name: `${t.name} - ${s.name}`,
                            })
                          }
                          value={
                            activeFeeds.find(
                              (af) =>
                                af.sourceId === s.sourceId && af.url === s.url
                            ) != null
                          }
                        />
                      </div>
                    ))}
                  </SelectableTerm>
                );
              })}
            </TaxonomyWrapper>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 300px;
  padding: 24px;
`;

const TaxonomyWrapper = styled.div`
  margin-bottom: 24px;
`;

const SelectableTerm = styled.div`
  margin-top: 12px;
`;
