import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import { useRef, useState } from "react";
import { CollectionHeader } from "../components/CollectionHeader";
import { Button } from "../components/input/Button";
import { Select } from "../components/input/Select";
import { TextInput } from "../components/input/TextInput";
import { PageContent } from "../components/PageContent";
import { ValueForm } from "../components/props/ValueForm";
import { ValueView } from "../components/props/ValueView";
import { SingleScrapeResultView, useScraper } from "features/scraper";
import { MultiScrapeResultView } from "features/scraper";
import { MultiResult, NoResult, SingleResult } from "features/scraper";
import { useApi } from "../hooks/useApi";
import { useCollection } from "../hooks/useCollection";
import { useSettings } from "../hooks/useSettings";
import { uploadImage } from "../services/uploadImage";
import { Item, ItemInput } from "../types";

enum State {
  idle,
  setConfig,
  saving,
}

const BulkAddPage = (): JSX.Element => {
  const { collection } = useCollection();
  const { settings } = useSettings();
  const api = useApi();
  const { scrape } = useScraper();
  const [state, setState] = useState<State>(State.idle);
  const queryElement = useRef<HTMLDivElement>(null);
  const [sourceId, setSourceId] = useState(
    String(collection.sources[0]?.id) ?? ""
  );
  const [customPropData, setCustomPropData] = useState<Record<string, string>>(
    {}
  );
  const [query, setQuery] = useState("");
  const [result, setResult] = useState<
    SingleResult | MultiResult | NoResult | null
  >(null);

  const search = async (queryOverride: string | null = null) => {
    if (sourceId.trim() === "" || query.trim() === "") return;

    setResult(null);

    const useOverride = queryOverride != null && queryOverride.trim() !== "";

    if (useOverride) {
      setQuery(queryOverride);
    }

    const result = await scrape({
      sourceId: Number(sourceId),
      query: useOverride ? queryOverride : query,
    });
    setResult(result);
  };

  const autofocus = () => {
    if (queryElement.current == null) return;

    queryElement.current.getElementsByTagName("input")[0].focus();
  };

  const dismiss = () => {
    setQuery("");
    setResult(null);
    autofocus();
  };

  const save = async () => {
    if (result == null || result.result == null || result.type !== "single")
      return;
    setState(State.saving);

    const saveData: ItemInput = {
      usedSources: [
        { sourceId: Number(sourceId), url: result.result.responseUrl },
      ],
      title: result.result.attributes.title.value,
      coverImageId: (
        await uploadImage({
          url: result.result.attributes.cover.value,
          settings,
          api,
        })
      ).id,
      customPropData: {
        ...customPropData,
        ...Object.entries(result.result.attributes)
          .filter(([key]) => key.startsWith("customProp-"))
          .reduce((prev, [key, scrapeValue]) => {
            prev[key.replace("customProp-", "")] = scrapeValue.value;
            return prev;
          }, {} as ItemInput["customPropData"]),
      },
      taxonomies: result.result.taxonomies,
    };

    await api.post<Item, ItemInput & { collectionId: number }>(`/items`, {
      ...saveData,
      collectionId: Number(collection.id),
    });

    setState(State.idle);
    setResult(null);
    setQuery("");
    autofocus();
  };

  return (
    <>
      <CollectionHeader />

      <PageContent>
        <Container>
          <FormRow
            onSubmit={(e) => {
              e.preventDefault();
              search();
            }}
          >
            {state === State.setConfig && (
              <div>
                <Button onClick={() => setState(State.idle)}>save</Button>
                <Select
                  label="Source"
                  options={collection.sources.map((s) => ({
                    label: s.name,
                    value: String(s.id),
                  }))}
                  value={sourceId}
                  onChange={setSourceId}
                />
                {collection.customProps.map((customProp) => {
                  return (
                    <ValueForm
                      key={customProp.id}
                      prop={customProp}
                      onChange={(newValue: string) => {
                        setCustomPropData({
                          ...customPropData,
                          [String(customProp.id)]: newValue,
                        });
                      }}
                      value={
                        (customPropData[String(customProp.id)] as string) || ""
                      }
                      label={customProp.name}
                    />
                  );
                })}
              </div>
            )}

            {state !== State.setConfig && sourceId.trim() !== "" && (
              <div>
                <Button onClick={() => setState(State.setConfig)}>
                  config
                </Button>
                <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                  <p>Source</p>
                  {
                    collection.sources.find((s) => s.id === Number(sourceId))
                      ?.name
                  }
                </div>
                {collection.customProps.map((customProp) => {
                  if (
                    Object.keys(customPropData).includes(
                      String(customProp.id)
                    ) === false
                  )
                    return null;

                  return (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 12 }}
                    >
                      {customProp.name}
                      <ValueView
                        key={customProp.id}
                        prop={customProp}
                        value={customPropData[String(customProp.id)]}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <SearchRow ref={queryElement}>
              <TextInput
                autoFocus
                label="Query"
                value={query}
                onChange={setQuery}
              />
              <Button type="submit">Search</Button>
            </SearchRow>
          </FormRow>

          <div style={{ marginBottom: 124 }}>
            {result?.type === "single" && (
              <SingleScrapeResultView result={result.result} />
            )}

            {result?.type === "multi" && (
              <MultiScrapeResultView
                result={result?.result}
                onSelect={({ sourceUrl }) => search(sourceUrl)}
              />
            )}
          </div>

          {result?.type === "single" && (
            <ActionsWrapper>
              <Button
                size="large"
                primary
                color="red"
                onClick={dismiss}
                disabled={state !== State.idle}
              >
                Dismiss
              </Button>

              <Button
                size="large"
                primary
                color="primary"
                onClick={save}
                disabled={state !== State.idle}
              >
                {state === State.saving ? "Adding..." : "Add"}
              </Button>
            </ActionsWrapper>
          )}
        </Container>
      </PageContent>
    </>
  );
};

const FormRow = styled.form`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const SearchRow = styled.div`
  display: flex;
  gap: 12px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background: white;
  border-top: 3px solid black;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export default BulkAddPage;
