import styled from "@emotion/styled";
import { Switch } from "../input/Switch";
import { TextInput } from "../input/TextInput";
import { SourceElement } from "./SourceElement";
import { SourceInput } from "./SourceInput";

export type TaxonomyInputSelectors = {
  [key: string]: {
    targetSelector: string;
    attributes: {
      name: SourceElement;
      source: SourceElement;
    };
  };
};

export const TaxonomySource = ({
  name,
  keyName,
  selectors,
  onChange,
}: {
  name: string;
  keyName: string;
  selectors: TaxonomyInputSelectors;
  onChange: (newSelectors: TaxonomyInputSelectors) => void;
}) => {
  const selector = selectors[keyName];

  const toggleEnabled = () => {
    // Add new selector for this keyName
    if (selector == null) {
      onChange({
        ...selectors,
        [keyName]: {
          targetSelector: "",
          attributes: {
            name: new SourceElement("name"),
            source: new SourceElement("source"),
          },
        },
      });
      return;
    }

    // Remove existing element from the selectors
    onChange(
      Object.entries(selectors).reduce((prev, [key, selector]) => {
        if (key === keyName) return prev;

        prev[key] = selector;

        return prev;
      }, {} as TaxonomyInputSelectors)
    );
  };

  const updateSelector = ({
    part,
    newSource,
  }: {
    part: "name" | "source";
    newSource: SourceElement;
  }) => {
    let updatedSelector = selectors[keyName];
    updatedSelector.attributes[part] = newSource;
    onChange({ ...selectors, [keyName]: updatedSelector });
  };

  const updateTargetSelector = (newValue) => {
    let updatedSelector = selectors[keyName];
    updatedSelector.targetSelector = newValue;
    onChange({ ...selectors, [keyName]: updatedSelector });
  };

  return (
    <Wrapper>
      <p>
        {name}{" "}
        <Switch
          label="enabled"
          value={selector != null}
          onChange={toggleEnabled}
        />
      </p>

      {selector != null && (
        <>
          <TextInput
            label="Target selector"
            value={selector.targetSelector}
            onChange={updateTargetSelector}
          />
          <SourceInput
            source={selector.attributes.name}
            onChange={(newSource) =>
              updateSelector({ part: "name", newSource })
            }
          />
          <SourceInput
            source={selector.attributes.source}
            onChange={(newSource) =>
              updateSelector({ part: "source", newSource })
            }
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.fieldset`
  padding: 0;
  border: 0;
`;
