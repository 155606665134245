import styled from "@emotion/styled";
import { CustomPropFilter } from "../types";
import { CustomPropFilterInput } from "./custom-prop-filter-input";

export const CustomPropsFilter = ({
  filters,
}: {
  filters: CustomPropFilter[];
}) => {
  return (
    <div>
      {filters.map((filter) => {
        return (
          <Wrapper key={filter.prop.id}>
            <CustomPropFilterInput filter={filter} />
          </Wrapper>
        );
      })}
    </div>
  );
};

const Wrapper = styled.div`
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0;
  }
`;
