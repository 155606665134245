import styled from "@emotion/styled";
import { Button } from "components/input/Button";
import { TextInput } from "components/input/TextInput";
import { CustomProp, CustomPropsFilter } from "features/custom-props";
import { Spacer } from "features/ui";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-hooks";
import {
  clearFilters,
  selectCustomPropFilterValue,
  selectSearchQuery,
  setCustomPropFilterValue,
  setSearchQuery,
} from "../taxonomy-slice";

export const TaxonomyFilters = ({
  taxonomyId,
  customProps,
}: {
  taxonomyId: number;
  customProps: CustomProp[];
}) => {
  const dispatch = useAppDispatch();
  const [localQuery, setLocalQuery] = useState<string>(
    useAppSelector(selectSearchQuery(Number(taxonomyId)))
  );
  const propFilterValues = useAppSelector(
    selectCustomPropFilterValue(taxonomyId)
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        setSearchQuery({
          taxonomyId,
          newQuery: localQuery,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, localQuery, taxonomyId]);

  const customPropFilters = customProps.map((prop) => {
    const match = propFilterValues.find((v) => v.id === prop.id);

    return {
      prop,
      onChange: (newValue) =>
        dispatch(
          setCustomPropFilterValue({
            taxonomyId,
            propFilterValue: { id: prop.id, value: newValue },
          })
        ),
      value: match != null ? match.value : "",
    };
  });

  const clear = () => {
    setLocalQuery("");
    dispatch(clearFilters(taxonomyId));
  };

  return (
    <Wrapper>
      <div style={{ alignSelf: "flex-end" }}>
        <Button onClick={clear}>Clear</Button>
      </div>

      <Spacer h={24} />

      <TextInput
        label="Search"
        value={localQuery}
        onChange={setLocalQuery}
        autoFocus
      />

      {customPropFilters.length > 0 && (
        <>
          <Spacer h={24} />

          <SectionTitle>Custom props</SectionTitle>
          <Spacer h={12} />
          <CustomPropsFilter filters={customPropFilters} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h3``;
