import { useCallback, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { isEqual } from "lodash";

enum Status {
  INITIALIZING = "initializing",
  LOADING = "loading",
  INITIALIZED = "initialized",
}

export type QueryParams = Record<string | number, string | number>;

type Options = {
  url: string;
  queryParams?: QueryParams;
};

export const useResource = <ResourceType>(
  { url, queryParams = {} }: Options,
  deps: any[] = []
) => {
  const { get } = useApi();
  const [firstLoad, setFirstLoad] = useState(true);
  const [status, setStatus] = useState<Status>(Status.INITIALIZING);
  const [dependencies, setDependencies] = useState<any[]>(deps);
  const [resource, setResource] = useState<ResourceType>();

  const queryString = Object.entries(queryParams)
    .map(([key, value]) => key + "=" + value)
    .join("&");

  const loadResource = useCallback(async () => {
    if (status !== Status.INITIALIZING) return;
    setStatus(Status.LOADING);

    const { data } = await get<ResourceType>(`${url}?${queryString}`);

    setResource(data);
    if (firstLoad) setFirstLoad(false);
    setStatus(Status.INITIALIZED);
  }, [firstLoad, get, queryString, status, url]);

  useEffect(() => {
    loadResource();
  }, [loadResource, status]);

  useEffect(() => {
    if (isEqual(deps, dependencies)) return;

    setDependencies(deps);
    setStatus(Status.INITIALIZING);
  }, [deps, dependencies]);

  const refetch = useCallback(() => {
    setStatus(Status.INITIALIZING);
  }, []);

  return {
    loading: status !== Status.INITIALIZED,
    firstLoad,
    resource,
    refetch,
  };
};
