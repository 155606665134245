import styled from "@emotion/styled";
import { ReactElement } from "react";

type PageContentProps = {
  children: ReactElement;
};

export const PageContent = ({ children }: PageContentProps): JSX.Element => {
  return <Main>{children}</Main>;
};

const Main = styled.main`
  margin-top: 50px;
`;
