export class SourceElement {
  name = "";
  selector = "";
  attribute = "";
  transformers: { type: string; options?: { [key: string]: string } }[] = [];

  constructor(name?: string) {
    if (name == null) return;

    this.name = name;
  }
}
