import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Container from "@mui/material/Container";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Divider from "@mui/material/Divider";
import { Drawer } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { useCollection } from "hooks/useCollection";

type HeaderProps = {
  buttons?: HeaderButton[];
  titleLinkTo?: string;
  title?: string;
  card?: ReactElement;
  secondaryButtons?: SecondaryButton[];
  secondaryMenus?: SecondaryMenuOptions[];
};

export type SecondaryButton = {
  icon: ReactElement;
  onClick: () => void;
};

export type SecondaryMenuOptions = {
  element: ReactElement;
  buttonIcon: ReactElement;
};

export const Header = ({
  buttons = [],
  titleLinkTo,
  title,
  card,
  secondaryButtons = [],
  secondaryMenus = [],
}: HeaderProps): JSX.Element => {
  const { collection } = useCollection();
  const drawerWidth = 240;
  const [showMenu, setShowMenu] = useState(false);
  const [showSecondaryMenu, setShowSecondaryMenu] = useState<number>(-1);
  const titleLink =
    titleLinkTo ?? collection != null ? `/collections/${collection.id}` : "/";

  const toggleMenu = (open?: boolean) => {
    if (open != null) {
      setShowMenu(open);
    } else {
      setShowMenu(!showMenu);
    }
  };

  const toggleSecondaryMenu = (index: number) => {
    if (showSecondaryMenu === index) {
      setShowSecondaryMenu(-1);
    } else {
      setShowSecondaryMenu(index);
    }
  };

  const renderButton = (button: HeaderButton) => {
    const buttonContent = (
      <ListItem
        key={button.text}
        button
        onClick={() => {
          if (button.onClick != null) button.onClick();
        }}
      >
        <ListItemIcon>{button.icon}</ListItemIcon>
        <ListItemText primary={button.text} />
      </ListItem>
    );

    if (button.onClick != null || button.linkTo == null) return buttonContent;

    return <Link to={button.linkTo}>{buttonContent}</Link>;
  };

  const appendedButtons = [
    ...buttons,
    { text: "DIVIDER-general" },
    {
      linkTo: "/",
      text: "Overview",
      icon: <HomeOutlinedIcon />,
    },
    {
      linkTo: "/settings",
      text: "User settings",
      icon: <SettingsOutlinedIcon />,
    },
    {
      linkTo: "/logout",
      text: "Logout",
      icon: <LogoutOutlinedIcon />,
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Container>
            <Toolbar>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => toggleMenu()}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Title>
                    <Link to={titleLink}>{title}</Link>
                  </Title>
                </div>

                <div>
                  {secondaryButtons.map((button, index) => {
                    return (
                      <IconButton
                        key={index}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => button.onClick()}
                      >
                        {button.icon}
                      </IconButton>
                    );
                  })}
                  {secondaryMenus.map((menu, index) => {
                    return (
                      <IconButton
                        key={index}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => toggleSecondaryMenu(index)}
                      >
                        {menu.buttonIcon}
                      </IconButton>
                    );
                  })}
                </div>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar />
      </Box>
      <SwipeableDrawer
        open={showMenu}
        onClose={() => toggleMenu(false)}
        onOpen={() => toggleMenu(true)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {card}
          <ButtonsWrapper>
            <List>
              {appendedButtons.map((button) => {
                if (button.text.startsWith("DIVIDER-")) {
                  return <Divider key={button.text} />;
                }

                return (
                  <React.Fragment key={button.text}>
                    {renderButton(button)}
                  </React.Fragment>
                );
              })}
            </List>
          </ButtonsWrapper>
        </Box>
      </SwipeableDrawer>
      {secondaryMenus.map((menu, index) => {
        return (
          <Drawer
            key={index}
            anchor="right"
            open={showSecondaryMenu === index}
            onClose={() => toggleSecondaryMenu(-1)}
            sx={{
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                boxSizing: "border-box",
              },
            }}
          >
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
              <div style={{ minWidth: 250 }}>{menu.element}</div>
            </Box>
          </Drawer>
        );
      })}
    </>
  );
};

const Title = styled.h1`
  a {
    text-decoration: none;
    font-size: 1.6rem;
    color: white;
  }
`;

const ButtonsWrapper = styled.div`
  a {
    text-decoration: none;
    color: black;
  }
`;

export type HeaderButton = {
  icon?: ReactElement;
  text: string;
  onClick?: Function;
  linkTo?: string;
};

export type Filter = {
  name: string;
  value: string;
};
