import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionHeader } from "../components/CollectionHeader";
import { Button } from "../components/input/Button";
import { ItemForm } from "../components/item/ItemForm";
import { PageContent } from "../components/PageContent";
import { useApi } from "../hooks/useApi";
import { useCollection } from "../hooks/useCollection";
import { CollectionIdInput, Item, ItemInput } from "../types";

const NewItemPage = (): JSX.Element => {
  const { collection } = useCollection();
  const { post } = useApi();
  const navigate = useNavigate();
  const { id: collectionId } = useParams();
  type ApiInput = ItemInput & CollectionIdInput;

  const save = async (itemData: ItemInput) => {
    const { data: item } = await post<Item, ApiInput>(`/items`, {
      ...itemData,
      collectionId: Number(collectionId),
    });

    navigate(`/collections/${item.collectionId}`);
  };

  return (
    <>
      <CollectionHeader />

      <PageContent>
        <Container>
          <BulkButton>
            <Button
              onClick={() => navigate(`/collections/${collection.id}/bulk-add`)}
            >
              Bulk mode
            </Button>
          </BulkButton>

          <ItemForm onSubmit={save} />
        </Container>
      </PageContent>
    </>
  );
};

const BulkButton = styled.div`
  float: right;
`;

export default NewItemPage;
