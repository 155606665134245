import styled from "@emotion/styled";

type Props = {
  value?: string;
};

export const BooleanView = ({ value }: Props) => {
  if (value == null) {
    return <Wrapper>(emtpy)</Wrapper>;
  }

  return <Wrapper>{value}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  background: lightgray;
`;
