import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";
import { config } from "../config";

export const useApi = (): ApiGateway => {
  const { getAccessTokenSilently } = useAuth0();

  const createAxiosInstance = async (): Promise<AxiosInstance> => {
    const accessToken = await getAccessTokenSilently();

    return axios.create({
      baseURL: config.apiUri,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  return {
    async get(path, options) {
      const localAxios = await createAxiosInstance();

      let compiledPath = path;

      if (options?.params != null) {
        compiledPath += "?";
        compiledPath += Object.entries(options?.params ?? [])
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
      }

      const { data } = await localAxios.get(compiledPath);

      return data;
    },
    async post(path, data) {
      const localAxios = await createAxiosInstance();

      const { data: responseData } = await localAxios.post(path, data);

      return responseData;
    },
    async del(path) {
      const localAxios = await createAxiosInstance();

      const { data } = await localAxios.delete(path);

      return data;
    },
  };
};

export interface ApiGateway {
  get<R>(
    path: string,
    options?: {
      params?: Record<string, string | number | null | string[] | number[]>;
    }
  ): Promise<{ data: R }>;
  post<R, I>(path: string, data: I): Promise<{ data: R }>;
  del(path: string): Promise<{ success: boolean }>;
}
