import styled from "@emotion/styled";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { CloudImage } from "components/CloudImage";
import { VisibilityIcon } from "components/taxonomy/VisibilityIcon";
import { styleConfig } from "styleConfig";
import { SingleScrapeResult } from "types";

export enum ScrapedTermAction {
  HIDDEN = "HIDDEN",
  VISIBLE = "VISIBLE",
}

export const ScrapedTaxonomiesView = ({
  taxonomies,
  newTermChanges = [],
  onUpdateNewTermChanges,
}: {
  taxonomies: SingleScrapeResult["taxonomies"];
  newTermChanges?: {
    taxonomyId: number;
    name: string;
    action: ScrapedTermAction;
  }[];
  onUpdateNewTermChanges?: (
    updatedNewTermChanges: {
      taxonomyId: number;
      name: string;
      action: ScrapedTermAction;
    }[]
  ) => void;
}) => {
  const findNewTermChange = ({
    name,
    taxonomyId,
  }: {
    name: string;
    taxonomyId: number;
  }): { name: string; taxonomyId: number; action: ScrapedTermAction } => {
    const change = newTermChanges.find((newTerm) => {
      return newTerm.name === name && newTerm.taxonomyId === taxonomyId;
    });

    if (change == null) {
      return {
        name,
        taxonomyId,
        action: ScrapedTermAction.HIDDEN,
      };
    }

    return change;
  };

  const toggleVisibility = ({
    name,
    taxonomyId,
  }: {
    name: string;
    taxonomyId: number;
  }) => {
    const current = findNewTermChange({ name, taxonomyId });

    const newTermChange = {
      name,
      taxonomyId,
      action:
        current.action === ScrapedTermAction.HIDDEN
          ? ScrapedTermAction.VISIBLE
          : ScrapedTermAction.HIDDEN,
    };

    const filteredNewTermChanges = newTermChanges.filter(
      (ntc) => ntc.name !== newTermChange.name
    );

    if (typeof onUpdateNewTermChanges === "function") {
      onUpdateNewTermChanges([...filteredNewTermChanges, newTermChange]);
    }
  };

  return (
    <TaxonomiesWrapper>
      {taxonomies.map((taxonomy) => (
        <TaxonomyWrapper key={taxonomy.parent.id}>
          <h3>{taxonomy.parent.name}</h3>

          <MatchedTerms>
            {taxonomy.results
              .filter((r) => r.matchedTerm != null)
              .map((term) => (
                <h4 key={term.attributes.name.value}>
                  <div>
                    <div className="img-wrapper">
                      {term.matchedTerm!.avatar != null && (
                        <CloudImage
                          image={term.matchedTerm!.avatar}
                          height={200}
                        />
                      )}
                      {term.matchedTerm?.hidden === true && (
                        <div className="visibility-icon">
                          <VisibilityIcon visible={false} />
                        </div>
                      )}
                    </div>
                    {term.matchedTerm!.name}
                  </div>
                  <a
                    href={term.attributes.source.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkOutlinedIcon />
                  </a>
                </h4>
              ))}
          </MatchedTerms>

          {taxonomy.results.filter((r) => r.matchedTerm == null).length > 0 && (
            <p style={{ marginTop: 24 }}>New terms</p>
          )}
          <NewTerms>
            {taxonomy.results
              .filter((r) => r.matchedTerm == null)
              .map((tr) => {
                const change = findNewTermChange({
                  name: tr.attributes.name.value,
                  taxonomyId: taxonomy.parent.id,
                });

                return (
                  <NewTerm key={tr.attributes.name.value}>
                    <h4>{tr.attributes.name.value}</h4>

                    {typeof onUpdateNewTermChanges === "function" && (
                      <div
                        onClick={() =>
                          toggleVisibility({
                            name: tr.attributes.name.value,
                            taxonomyId: taxonomy.parent.id,
                          })
                        }
                      >
                        <VisibilityIcon
                          visible={
                            change.action === ScrapedTermAction.HIDDEN
                              ? false
                              : true
                          }
                        />
                      </div>
                    )}

                    <a
                      href={tr.attributes.source.value}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkOutlinedIcon />{" "}
                    </a>
                  </NewTerm>
                );
              })}
          </NewTerms>
        </TaxonomyWrapper>
      ))}
    </TaxonomiesWrapper>
  );
};

const TaxonomiesWrapper = styled.div`
  margin-top: 24px;
`;

const TaxonomyWrapper = styled.div`
  padding-left: 12px;
  border-left: 4px solid lightgray;
`;

const MatchedTerms = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  .img-wrapper {
    min-height: 100px;
    position: relative;
    background: lightgray;

    .visibility-icon {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }

  @media (max-width: ${styleConfig.breakpoints.mobile}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const NewTerms = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NewTerm = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 15px;
  background: lightgray;
`;
