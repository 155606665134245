import styled from "@emotion/styled";

export const Alert = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  padding: 12px;
  border: 2px solid orange;
  background: lightgoldenrodyellow;
`;
