import styled from "@emotion/styled";
import { Switch } from "../input/Switch";
import { SourceElement } from "./SourceElement";
import { SourceInput } from "./SourceInput";

export type CustomPropInputSelectors = {
  [key: string]: SourceElement;
};

export const CustomPropsSource = ({
  name,
  keyName,
  selectors,
  onChange,
}: {
  name: string;
  keyName: string;
  selectors: CustomPropInputSelectors;
  onChange: (newSelectors: CustomPropInputSelectors) => void;
}) => {
  const value = selectors[keyName];

  const toggleEnabled = () => {
    // Add new selector for this keyName
    if (value == null) {
      onChange({ ...selectors, [keyName]: new SourceElement(name) });
      return;
    }

    // Remove existing element from the selectors
    onChange(
      Object.entries(selectors).reduce((prev, [key, selector]) => {
        if (key === keyName) return prev;

        prev[key] = selector;

        return prev;
      }, {} as CustomPropInputSelectors)
    );
  };

  const updateSourceElement = (newSource: SourceElement) => {
    onChange({ ...selectors, [keyName]: newSource });
  };

  return (
    <Wrapper>
      <p>
        {name}{" "}
        <Switch
          label="enabled"
          value={value != null}
          onChange={toggleEnabled}
        />
      </p>

      {value != null && (
        <SourceInput
          source={value}
          onChange={updateSourceElement}
          hideName={true}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.fieldset`
  padding: 0;
  border: 0;
`;
