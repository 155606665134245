export interface BasicCustomPropInput {
  name: string;
  type: CustomPropType;
}

export interface CustomProp extends BasicCustomPropInput {
  id: number;
  target: CustomPropTarget;
  collectionId?: number;
  taxonomyId?: number;
}

export enum CustomPropType {
  URL = "URL",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  TEXT = "TEXT",
}

export enum CustomPropTarget {
  COLLECTION = "COLLECTION",
  TAXONOMY = "TAXONOMY",
}

export interface CustomPropValue {
  id: number;
  value: string;
  customPropId: number;
}

export enum FilterDefaults {
  SET = "__set__",
  EMPTY = "__empty__",
}

export type CustomPropFilter = {
  onChange: (newValue: string) => void;
  prop: CustomProp;
  value?: string;
};

export type PropFilterValue = {
  id: number;
  value: string;
};
