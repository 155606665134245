import styled from "@emotion/styled";
import { ScrapeTargetTransformer } from "../../types";
import { Select } from "../input/Select";
import { TextInput } from "../input/TextInput";

const TransformerTypes: {
  [key: string]: {
    options?: (string | { name: string; required?: boolean })[];
  };
} = {
  url: {
    options: ["baseUrl"],
  },
  date: {
    options: [{ name: "format", required: false }],
  },
  regexReplace: {
    options: [
      { name: "regexPattern", required: true },
      { name: "replacement", required: true },
    ],
  },
};

export const TransformerInput = ({
  type,
  options = {},
  onChange,
}: {
  type: string;
  options?: { [key: string]: string };
  onChange: (transformer: ScrapeTargetTransformer) => void;
}) => {
  return (
    <Wrapper>
      <Select
        value={type}
        onChange={(value) => {
          onChange({ type: value, options });
        }}
        options={Object.keys(TransformerTypes).map((o) => ({
          label: o,
          value: o,
        }))}
      />

      {(TransformerTypes[type]?.options || []).map((o) => {
        const optionName = typeof o === "string" ? o : o.name;

        return (
          <TextInput
            key={optionName}
            label={
              optionName +
              (typeof o !== "string" && o.required ? "" : " (optional)")
            }
            value={options[optionName]}
            onChange={(value) => {
              let clone = Object.assign({}, options);

              onChange({
                type,
                options: { ...clone, [optionName]: value },
              });
            }}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > * {
    margin-top: 12px;
  }
`;
