import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { MultiScrapeResult, SingleScrapeResult, Source } from "../../types";
import { Button } from "../input/Button";
import { TextInput } from "../input/TextInput";
import { ScrapeResult } from "./ScrapeResult";

enum State {
  startingCall,
  loadingCall,
  ready,
}

export const Scraper = ({
  source,
  onSingleResult = () => {},
  initialQuery,
  hideTaxonomies = false,
  debug = false,
}: {
  source: Source;
  onSingleResult?: (result: SingleScrapeResult) => void;
  initialQuery?: string;
  hideTaxonomies?: boolean;
  debug?: boolean;
}) => {
  const { post } = useApi();
  const [state, setState] = useState<State>(State.ready);
  const [scraperCalls, setScraperCalls] = useState<
    {
      query: string;
      result?: SingleScrapeResult | MultiScrapeResult;
      error?: string;
    }[]
  >(initialQuery == null ? [] : [{ query: initialQuery }]);
  const [query, setQuery] = useState("");
  const [currentCallIndex, setCurrentCallIndex] = useState<number>(0);
  const currentCall = scraperCalls[currentCallIndex];
  const lastCall = scraperCalls[scraperCalls.length - 1];

  if (debug) console.log({ scraperCalls });

  const updateLastCall = useCallback(
    ({
      result,
      error,
    }: {
      result?: SingleScrapeResult | MultiScrapeResult;
      error?: string;
    }) => {
      setScraperCalls(
        scraperCalls.map((call, index) => {
          if (index !== scraperCalls.length - 1) return call;

          return {
            ...call,
            ...(result != null ? { result } : {}),
            ...(error != null ? { error } : {}),
          };
        })
      );
    },
    [scraperCalls]
  );

  const handleCurrentIndexChange = useCallback(
    (index: number) => {
      setCurrentCallIndex(index);
      setQuery(scraperCalls[index].query);
    },
    [scraperCalls]
  );

  useEffect(() => {
    const callScraper = async () => {
      if (
        state === State.loadingCall ||
        lastCall?.query == null ||
        lastCall?.error != null ||
        lastCall?.result != null
      )
        return;
      setState(State.loadingCall);

      try {
        const result = (await post(`/scrape`, {
          sourceId: source.id,
          query: lastCall.query,
        })) as any;

        if (result.data.type === "multiResult") {
          updateLastCall({ result: result.data as MultiScrapeResult });
        } else {
          const singleResult = result.data as SingleScrapeResult;
          updateLastCall({ result: singleResult });
          onSingleResult(singleResult);
        }
      } catch (e: any) {
        updateLastCall({ error: e.message as string });
      } finally {
        setState(State.ready);
        handleCurrentIndexChange(scraperCalls.length - 1);
      }
    };

    callScraper();
  }, [
    handleCurrentIndexChange,
    lastCall?.error,
    lastCall?.query,
    lastCall?.result,
    onSingleResult,
    post,
    scraperCalls.length,
    source.id,
    state,
    updateLastCall,
  ]);

  const createNewCall = (query: string, replaceExisting = false) => {
    const matchedCallIndex = scraperCalls.findIndex(
      (call) => call.query === query
    );

    const newScraperCallsOrder = [
      ...scraperCalls.filter(
        (_, index) => index !== matchedCallIndex && index !== currentCallIndex
      ),
      ...(scraperCalls[currentCallIndex] != null
        ? [scraperCalls[currentCallIndex]]
        : []),
      ...(matchedCallIndex === -1 ? [] : [scraperCalls[matchedCallIndex]]),
    ];

    if (matchedCallIndex === -1) {
      setScraperCalls([...newScraperCallsOrder, { query }]);
      return;
    }

    if (matchedCallIndex > -1 && replaceExisting) {
      setScraperCalls([
        ...newScraperCallsOrder.filter((call) => call.query !== query),
        { query },
      ]);
      return;
    }

    setScraperCalls(newScraperCallsOrder);
    handleCurrentIndexChange(scraperCalls.length - 1);
    setState(State.ready);
  };

  const handleRun = async () => {
    if (state !== State.ready) return;
    setState(State.startingCall);
    createNewCall(query);
  };

  return (
    <>
      <h2>
        {source.name}
        <span>
          <Button
            onClick={() => handleCurrentIndexChange(currentCallIndex - 1)}
            disabled={currentCallIndex === 0}
          >
            Prev
          </Button>
          <Button
            onClick={() => handleCurrentIndexChange(currentCallIndex + 1)}
            disabled={
              scraperCalls.length === 0 ||
              currentCallIndex === scraperCalls.length - 1
            }
          >
            Next
          </Button>
        </span>
      </h2>

      <TestInput
        onSubmit={(e) => {
          e.preventDefault();
          handleRun();
        }}
      >
        <TextInput
          value={query}
          label="Query"
          onChange={setQuery}
          disabled={state !== State.ready}
          autoFocus
        />
        <Button
          disabled={state !== State.ready}
          onClick={() => createNewCall(currentCall.query, true)}
        >
          Retry
        </Button>
        <Button type="submit" disabled={state !== State.ready}>
          Run
        </Button>
      </TestInput>

      {state !== State.ready && <p>Loading...</p>}
      {currentCall?.error != null && <p>{currentCall.error}</p>}

      {state === State.ready && currentCall?.result != null && (
        <ScrapeResult
          result={currentCall.result}
          onSelectMultiResult={(item) => {
            createNewCall(item.source.value);
          }}
          onSelectSingleResult={() => {}}
          hideTaxonomies={hideTaxonomies}
        />
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: 48,
        }}
      >
        {state === State.ready &&
          currentCall?.result != null &&
          currentCall.result.type === "multiResult" &&
          currentCall.result.nextPageSource != null && (
            <Button
              primary
              onClick={() =>
                currentCall.result?.type === "multiResult" &&
                typeof currentCall.result.nextPageSource === "string" &&
                createNewCall(currentCall.result.nextPageSource)
              }
            >
              Next page
            </Button>
          )}
      </div>
    </>
  );
};

const TestInput = styled.form`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;
