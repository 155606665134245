import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <p>Redirecting for login...</p>;
};
