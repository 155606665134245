import { useApi } from "../../hooks/useApi";
import { useCollection } from "../../hooks/useCollection";
import { SourceForm } from "./SourceForm";

export const AddSource = ({
  onComplete,
}: {
  onComplete: () => void;
}): JSX.Element => {
  const { collection, refetchCollection } = useCollection();
  const { post } = useApi();

  const handleSave = async (config: Record<string, any>) => {
    await post(`/sources`, {
      collectionId: collection.id,
      name: config.basic.name,
      config,
    });

    await refetchCollection();

    onComplete();
  };

  return (
    <>
      <SourceForm onSave={handleSave} />
    </>
  );
};
