import axios from "axios";
import { ApiGateway } from "../hooks/useApi";
import { UserSettings } from "../hooks/useSettings";
import { Image, ImageInput } from "../types";

type Props = {
  url: string;
  api: ApiGateway;
  settings: UserSettings;
};

export const uploadImage = async (props: Props): Promise<Image> => {
  const { url, meta } = await uploadToImageKit(props);

  const { data: image } = await props.api.post<Image, ImageInput>("/images", {
    url,
    meta,
  });

  return image;
};

export const uploadToImageKit = async (props: {
  url: string;
  settings: UserSettings;
}): Promise<{ url: string; meta: string }> => {
  const uploadUrl = "https://upload.imagekit.io/api/v1/files/upload";

  const bodyFormData = new FormData();
  bodyFormData.append("fileName", "foo");
  bodyFormData.append("file", props.url);
  bodyFormData.append("folder", props.settings.imageKit_folder ?? "");

  const { data } = await axios({
    method: "POST",
    url: uploadUrl,
    data: bodyFormData,
    headers: {
      Authorization: `Basic ${btoa(props.settings.imageKit_privateKey + ":")}`,
      "Content-Type": "multipart/form-data",
    },
  });

  return {
    url: data.url,
    meta: JSON.stringify({ fileId: data.fileId, filePath: data.filePath }),
  };
};
