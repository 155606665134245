import { CustomProp, CustomPropType } from "features/custom-props";
import { BooleanView } from "./propTypes/BooleanView";
import { DateView } from "./propTypes/DateView";
import { TextView } from "./propTypes/TextView";
import { UrlView } from "./propTypes/UrlView";

type ViewProps = {
  prop: CustomProp;
  value?: any;
};

export const ValueView = ({ prop, value, ...rest }: ViewProps): JSX.Element => {
  const props = {
    value,
    ...rest,
  };

  if (prop.type === CustomPropType.URL) {
    return <UrlView {...props} />;
  }

  if (prop.type === CustomPropType.DATE) {
    return <DateView {...props} />;
  }

  if (prop.type === CustomPropType.BOOLEAN) {
    return <BooleanView {...props} />;
  }

  if (prop.type === CustomPropType.TEXT) {
    return <TextView {...props} />;
  }

  return <>Unsupported custom prop type</>;
};
