import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { useEffect, useState } from "react";
import { FileManager } from "../input/FileManager";
import { Image, Item, SingleScrapeResult } from "../../types";
import { TextInput } from "../input/TextInput";
import { ScraperPopup } from "../sources/ScraperPopup";
import { useCollection } from "../../hooks/useCollection";
import { ValueForm } from "../props/ValueForm";
import { useApi } from "../../hooks/useApi";
import { ScrapedTaxonomiesView, ScrapedTermAction } from "features/scraper";

type ItemFormProps = {
  item?: Item;
  onSubmit: (input: {
    title: string;
    coverImageId: number;
    customPropData: Record<string, string>;
    usedSources: UsedSource[];
    taxonomies?: SingleScrapeResult["taxonomies"];
    newTermChanges?: {
      taxonomyId: number;
      name: string;
      action: ScrapedTermAction;
    }[];
  }) => void;
  editing?: boolean;
};

type UsedSource = {
  sourceId: number;
  url: string;
};

export const ItemForm = ({
  item,
  onSubmit,
  editing = false,
}: ItemFormProps): JSX.Element => {
  const { post } = useApi();
  const { collection } = useCollection();
  const [title, set_title] = useState(item?.title ?? "");
  const [coverImage, setCoverImage] = useState<Image | null>(
    item?.coverImage ?? null
  );
  const [showScraper, setShowScraper] = useState(false);
  const [rawImageUri, setRawImageUri] = useState("");
  const [customPropData, setCustomPropData] = useState<Record<string, string>>(
    item == null
      ? {}
      : item.customProps.reduce((prev, current) => {
          prev[current.customPropId] = current.value;
          return prev;
        }, {})
  );
  const [scrapedTaxonomies, setScrapedTaxonomies] = useState<
    SingleScrapeResult["taxonomies"]
  >([]);
  const [newTermChanges, setNewTermChanges] = useState<
    { taxonomyId: number; name: string; action: ScrapedTermAction }[]
  >([]);
  const [usedSources, setUsedSources] = useState<UsedSource[]>([]);
  const [duplicateItem, setDuplicateItem] = useState<Item | null>(null);

  useEffect(() => {
    const checkDuplicateItem = async () => {
      if (usedSources.length === 0) return;

      const { data: item } = await post<Item | null, any>(
        `/items/findDuplicate`,
        {
          usedSources,
          collectionId: collection.id,
        }
      );

      setDuplicateItem(item);
    };

    checkDuplicateItem();
  }, [collection.id, post, usedSources]);

  const submit = () => {
    if (coverImage == null) return;

    onSubmit({
      title,
      coverImageId: coverImage.id,
      customPropData,
      usedSources,
      taxonomies: scrapedTaxonomies,
      newTermChanges,
    });
  };

  const addUsedSource = ({ source }: { source: UsedSource }) => {
    setUsedSources([source]);
  };

  const handleScraperData = (result: SingleScrapeResult) => {
    addUsedSource({
      source: {
        sourceId: result.sourceId,
        url: result.responseUrl,
      },
    });

    if (Object.keys(result.attributes).includes("title")) {
      set_title(result.attributes.title.value);
    }

    if (Object.keys(result.attributes).includes("cover")) {
      setRawImageUri(result.attributes.cover.value);
    }

    // Set custom props
    const customPropsToUse = Object.keys(result.attributes).filter((u) =>
      u.startsWith("customProp-")
    );
    setCustomPropData({
      ...customPropData,
      ...customPropsToUse.reduce((prev, current) => {
        prev[current.replace("customProp-", "")] =
          result.attributes[current].value;
        return prev;
      }, {}),
    });

    if (!editing) {
      setScrapedTaxonomies(result.taxonomies);
    }

    setShowScraper(false);
  };

  return (
    <Box>
      <Button onClick={() => setShowScraper(true)}>Scrape</Button>

      <Form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();

          submit();
        }}
      >
        <Button
          type="submit"
          variant="contained"
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          {item == null ? "Create" : "Update"}
        </Button>

        <TextInput label="Title" onChange={set_title} value={title} />

        {usedSources.length > 0 && (
          <div style={{ marginTop: 24, marginBottom: 12 }}>
            {usedSources.map((us) => {
              const source = collection.sources.find(
                (s) => s.id === us.sourceId
              );

              if (source == null) return null;

              return (
                <div key={us.sourceId}>
                  <a href={us.url} target="_blank" rel="noreferrer">
                    {source.name}
                  </a>{" "}
                  {editing === false && duplicateItem != null && (
                    <a
                      href={`/collections/${collection.id}/items/${duplicateItem.id}`}
                    >
                      DUPLICATE!
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <FileManager
          onUpload={(image) => setCoverImage(image)}
          onEdit={(image) => setCoverImage(image)}
          existingFile={coverImage ?? undefined}
          croppingAspectRatio={1.5}
          uriInput={rawImageUri}
        />

        <CustomPropsInput>
          <h2>Custom props</h2>
          {collection.customProps.map((customProp) => {
            return (
              <ValueForm
                key={customProp.id}
                prop={customProp}
                onChange={(newValue: string) => {
                  setCustomPropData({
                    ...customPropData,
                    [customProp.id]: newValue,
                  });
                }}
                value={(customPropData[customProp.id] as string) || ""}
                label={customProp.name}
              />
            );
          })}
        </CustomPropsInput>

        {scrapedTaxonomies.length > 0 && (
          <TaxonomiesInput>
            <h2>Taxonomies</h2>
            <ScrapedTaxonomiesView
              taxonomies={scrapedTaxonomies}
              newTermChanges={newTermChanges}
              onUpdateNewTermChanges={setNewTermChanges}
            />
          </TaxonomiesInput>
        )}

        <Button
          type="submit"
          variant="contained"
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          {item == null ? "Create" : "Update"}
        </Button>
      </Form>

      {showScraper && (
        <ScraperPopup
          onCancel={() => setShowScraper(false)}
          onUse={handleScraperData}
          initialQuery={
            item != null && (item?.sources ?? []).length > 0
              ? {
                  sourceId: item.sources[0].source.id,
                  url: item.sources[0].url,
                }
              : undefined
          }
          hideTaxonomies={editing}
        />
      )}
    </Box>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomPropsInput = styled.div`
  width: 100%;
  margin-top: 36px;

  > * {
    margin-bottom: 12px;
  }
`;

const TaxonomiesInput = styled.div`
  width: 100%;
  margin-top: 36px;
`;
