import styled from "@emotion/styled";
import { ScrapeTargetTransformer } from "../../types";
import { Button } from "../input/Button";
import { Label } from "../input/Label";
import { TextInput } from "../input/TextInput";
import { SourceElement } from "./SourceElement";
import { TransformerInput } from "./TransformerInput";

type Props = {
  source: SourceElement;
  onChange: (source: SourceElement) => void;
  hideName?: boolean;
};

export const SourceInput = ({
  source,
  onChange,
  hideName = false,
}: Props): JSX.Element => {
  const handleChange = (attribute: string, value: string) => {
    source[attribute] = value;
    onChange(source);
  };

  const addTransformer = () => {
    source.transformers.push({ type: "" });
    onChange(source);
  };

  const updateTransformer = (index: number, value: ScrapeTargetTransformer) => {
    const updated = source.transformers.map((t, i) => {
      if (i !== index) return t;

      return value;
    });

    onChange({
      ...source,
      transformers: updated,
    });
  };

  const deleteTransformer = (index: number) => {
    let clone = [...source.transformers];

    clone.splice(index, 1);

    onChange({
      ...source,
      transformers: clone,
    });
  };

  const moveTransformer = (index: number, direction: number) => {
    const targetIndex = index + direction;

    if (source.transformers[targetIndex] == null) return;

    const newOrder = source.transformers.map((t, i) => {
      if (i !== index && i !== targetIndex) return t;

      if (i === index) return source.transformers[targetIndex];

      return source.transformers[index];
    });

    onChange({
      ...source,
      transformers: newOrder,
    });
  };

  return (
    <Wrapper>
      {!hideName && <p>{source.name}</p>}

      <TextInput
        value={source.selector}
        onChange={(value) => handleChange("selector", value)}
        label="selector"
      />

      <TextInput
        value={source.attribute}
        onChange={(value) => handleChange("attribute", value)}
        label="attribute (default: innerHTML)"
      />

      <Label>Transformers</Label>
      {source.transformers.map((t, i) => (
        <TransformerWrapper key={i}>
          <Label>
            # {i + 1}
            <LabelButton onClick={() => moveTransformer(i, -1)}>up</LabelButton>
            <LabelButton onClick={() => moveTransformer(i, 1)}>
              down
            </LabelButton>
            <LabelButton onClick={() => deleteTransformer(i)}>
              delete
            </LabelButton>
          </Label>
          <TransformerInput
            type={t.type}
            options={t.options}
            onChange={(value) => updateTransformer(i, value)}
          />
        </TransformerWrapper>
      ))}
      <Button size="small" onClick={addTransformer}>
        Add
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > * {
    margin: 12px 0;
  }
`;

const TransformerWrapper = styled.article`
  padding-left: 12px;
  border-left: 5px solid gray;
  margin-bottom: 12px;
`;

const LabelButton = styled.span`
  margin-left: 6px;
  cursor: pointer;

  ::before {
    content: "(";
  }

  ::after {
    content: ")";
  }
`;
