import styled from "@emotion/styled";
import { Button } from "components/input/Button";
import { TextInput } from "components/input/TextInput";
import { CustomPropsFilter } from "features/custom-props";
import { Spacer } from "features/ui";
import { useCollection } from "hooks/useCollection";
import { createRef, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux-hooks";
import {
  selectCustomPropFilterValues,
  selectSearchQuery,
  setCustomPropFilterValue,
  setSearchQuery,
  clearFilters,
} from "../collection-slice";
import { SectionSubTitle } from "./collection-sidemenu";

export const CollectionFilters = () => {
  const dispatch = useAppDispatch();
  const { collection } = useCollection();
  const searchQuery = useAppSelector(selectSearchQuery);
  const propFilterValues = useAppSelector(selectCustomPropFilterValues);
  const searchRef = createRef<HTMLDivElement>();

  const focusSearchInput = useCallback(() => {
    if (searchRef.current == null) return;

    searchRef.current.querySelector("input")!.focus();
  }, [searchRef]);

  useEffect(() => {
    focusSearchInput();
  }, [focusSearchInput]);

  const customPropFilters = collection.customProps.map((prop) => {
    const match = propFilterValues.find((v) => v.id === prop.id);

    return {
      prop,
      onChange: (newValue) =>
        dispatch(setCustomPropFilterValue({ id: prop.id, value: newValue })),
      value: match != null ? match.value : "",
    };
  });

  const onClearFilters = () => {
    dispatch(clearFilters());

    focusSearchInput();
  };

  return (
    <Wrapper>
      <div style={{ position: "absolute", top: -28, right: 0 }}>
        <Button onClick={onClearFilters}>Clear</Button>
      </div>

      <Spacer h={18} />

      <div ref={searchRef}>
        <TextInput
          label="Search"
          value={searchQuery}
          onChange={(newQuery) => dispatch(setSearchQuery(newQuery))}
        />
      </div>

      {customPropFilters.length > 0 && (
        <>
          <Spacer h={36} />

          <SectionSubTitle>Custom props</SectionSubTitle>
          <Spacer h={12} />
          <CustomPropsFilter filters={customPropFilters} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
