import { Image, ImageInput, ImageUpdate } from "../../types";
import { ImageKitUploader } from "./ImageKitUploader";
import styled from "@emotion/styled";
import { useApi } from "../../hooks/useApi";
import { useState } from "react";
import { CloudImage } from "../CloudImage";
import Button from "@mui/material/Button";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
  croppingAspectRatio?: number;
  existingFile?: Image;
  onEdit: (image: Image) => void;
  onUpload: (image: Image) => void;
  uriInput?: string;
};

enum Mode {
  UPLOADING,
  VIEWING,
  EDITING,
}

export const FileManager = ({
  croppingAspectRatio,
  existingFile,
  onEdit,
  onUpload,
  uriInput = "",
}: Props): JSX.Element => {
  const [mode, setMode] = useState<Mode>(
    existingFile != null ? Mode.VIEWING : Mode.UPLOADING
  );
  const [crop, setCrop] = useState<Crop>();
  const { post } = useApi();

  const handleUpload = async ({ url, meta }) => {
    const { data: image } = await post<Image, ImageInput>("/images", {
      url,
      meta,
    });

    onUpload(image);
    setMode(Mode.VIEWING);
  };

  const handleCrop = async () => {
    const { data: image } = await post<Image, ImageUpdate>(
      `/images/${existingFile?.id}`,
      { crop }
    );

    onEdit(image);
    setMode(Mode.VIEWING);
  };

  return (
    <Wrapper>
      {mode === Mode.VIEWING && (
        <>
          <CloudImage image={existingFile} height={260} />
          <Button onClick={() => setMode(Mode.UPLOADING)}>New image</Button>
          <Button onClick={() => setMode(Mode.EDITING)}>Crop</Button>
        </>
      )}

      {mode === Mode.EDITING && (
        <CropWrapper>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={croppingAspectRatio}
          >
            <CloudImage image={existingFile} crop={false} />
          </ReactCrop>
          <div>
            <Button
              style={{ marginTop: 24 }}
              onClick={() => setMode(Mode.VIEWING)}
            >
              Cancel
            </Button>
            <Button style={{ marginTop: 24 }} onClick={handleCrop}>
              Save
            </Button>
          </div>
        </CropWrapper>
      )}

      {mode === Mode.UPLOADING && (
        <>
          <ImageKitUploader onUpload={handleUpload} uriInput={uriInput} />
          {existingFile && (
            <Button
              style={{ marginTop: 24 }}
              onClick={() => setMode(Mode.VIEWING)}
            >
              Cancel
            </Button>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  border: 1px solid black;
  margin: 12px 0;
`;

const CropWrapper = styled.div``;
