import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Login } from "../pages/Login";
import { PageLoad } from "../components/PageLoad";
import { RequireAuthentication } from "../router/RequireAuthentication";
import NewItemPage from "../pages/NewItemPage";
import FeedPage from "../pages/FeedPage";
import BulkAddPage from "../pages/BulkAddPage";
const Logout = React.lazy(() => import("../pages/Logout"));
const CollectionsPage = React.lazy(
  () => import("pages/collection/collections-page")
);
const CollectionConfigPage = React.lazy(
  () => import("../pages/CollectionConfigPage")
);
const RescrapeCoversPage = React.lazy(
  () => import("pages/collection/rescrape-covers-page")
);
const ItemPage = React.lazy(() => import("../pages/ItemPage"));
const EditItemPage = React.lazy(() => import("../pages/EditItemPage"));
const EditTermPage = React.lazy(() => import("../pages/EditTermPage"));
const EditTaxonomyPage = React.lazy(() => import("../pages/EditTaxonomyPage"));
const NewCollectionPage = React.lazy(() => import("../pages/NewCollection"));
const CollectionPage = React.lazy(
  () => import("pages/collection/collection-page")
);
const TaxonomyPage = React.lazy(
  () => import("../pages/taxonomy/taxonomy-page")
);
const TermPage = React.lazy(() => import("../pages/TermPage"));
const UserSettings = React.lazy(() => import("../pages/UserSettings"));
const NewTermPage = React.lazy(() => import("../pages/NewTermPage"));
const RankPage = React.lazy(() => import("../pages/rank-page"));

export const RoutesList = () => (
  <Routes>
    <Route path="/login" element={<Login />} />

    <Route
      path="/logout"
      element={
        <Suspense fallback={<PageLoad />}>
          <Logout />
        </Suspense>
      }
    />

    <Route
      path="/collections/new"
      element={
        <Suspense fallback={<PageLoad />}>
          <NewCollectionPage />
        </Suspense>
      }
    />

    <Route
      path="/collections/:id"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <CollectionPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/config"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <CollectionConfigPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/rescrape-covers"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <RescrapeCoversPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/feeds/:sources"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <FeedPage />
          </Suspense>
        </RequireAuthentication>
      }
    />
    <Route
      path="/collections/:id/feeds"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <FeedPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/rank"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <RankPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/items/:itemId"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <ItemPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/taxonomies/:taxonomyId"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <TaxonomyPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/taxonomies/:taxonomyId/edit"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <EditTaxonomyPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/terms/:termId"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <TermPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/terms/:termId/edit"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <EditTermPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/taxonomies/:taxonomyId/terms/add"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <NewTermPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:collectionId/items/:itemId/edit"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <EditItemPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/new"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <NewItemPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/collections/:id/bulk-add"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <BulkAddPage />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/settings"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <UserSettings />
          </Suspense>
        </RequireAuthentication>
      }
    />

    <Route
      path="/"
      element={
        <RequireAuthentication>
          <Suspense fallback={<PageLoad />}>
            <CollectionsPage />
          </Suspense>
        </RequireAuthentication>
      }
    />
  </Routes>
);
