import { CustomProp, CustomPropType } from "features/custom-props";
import { BooleanForm } from "./propTypes/BooleanForm";
import { DateForm } from "./propTypes/DateForm";
import { TextForm } from "./propTypes/TextForm";
import { UrlForm } from "./propTypes/UrlForm";

type FormProps = {
  prop: CustomProp;
  onChange: (newValue: string) => void;
  label?: string;
  value?: string;
};

export const ValueForm = ({
  prop,
  value = "",
  label,
  onChange,
  ...rest
}: FormProps): JSX.Element => {
  const props = {
    value,
    onChange,
    label,
    ...rest,
  };

  if (prop.type === CustomPropType.URL) {
    return <UrlForm {...props} />;
  }

  if (prop.type === CustomPropType.DATE) {
    return <DateForm {...props} />;
  }

  if (prop.type === CustomPropType.BOOLEAN) {
    return <BooleanForm {...props} />;
  }

  if (prop.type === CustomPropType.TEXT) {
    return <TextForm {...props} />;
  }

  return <>Unsupported custom prop type</>;
};
