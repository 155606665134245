import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropFilterValue } from "features/custom-props";
import { RootState } from "store";

export const sortOptions = [
  "UPDATED_AT_DESC",
  "UPDATED_AT_ASC",
  "CREATED_AT_DESC",
  "CREATED_AT_ASC",
  "RANK_ASC",
  "RANK_DESC",
];

interface collectionState {
  overviewSettings: {
    showRank: boolean;
    showTitles: boolean;
  };
  customPropFilterValues: PropFilterValue[];
  searchQuery: string;
  sortBy: string;
}

const initialState: collectionState = {
  overviewSettings: {
    showRank: true,
    showTitles: true,
  },
  customPropFilterValues: [],
  searchQuery: "",
  sortBy: sortOptions[0],
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    clearCollection: (state) => {
      state.overviewSettings.showTitles = false;
      state.customPropFilterValues = [];
      state.searchQuery = "";
    },
    clearFilters: (state) => {
      state.customPropFilterValues = [];
      state.searchQuery = "";
    },
    setOverviewSettings: (
      state,
      action: PayloadAction<{ showTitles?: boolean; showRank?: boolean }>
    ) => {
      state.overviewSettings = {
        ...state.overviewSettings,
        ...action.payload,
      };
    },
    setCustomPropFilterValue: (
      state,
      action: PayloadAction<PropFilterValue>
    ) => {
      const otherValues = state.customPropFilterValues.filter(
        (pf) => pf.id !== action.payload.id
      );

      if (action.payload.value.trim() === "") {
        state.customPropFilterValues = [...otherValues];
      } else {
        state.customPropFilterValues = [...otherValues, action.payload];
      }
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
});

// Reducers
export const {
  clearCollection,
  clearFilters,
  setOverviewSettings,
  setCustomPropFilterValue,
  setSearchQuery,
  setSortBy,
} = collectionSlice.actions;

// Selectors
export const selectOverviewSettings = (state: RootState) =>
  state.collection.overviewSettings;
export const selectCustomPropFilterValues = (state: RootState) =>
  state.collection.customPropFilterValues;
export const selectSearchQuery = (state: RootState) =>
  state.collection.searchQuery;
export const selectSortBy = (state: RootState) => state.collection.sortBy;

export default collectionSlice.reducer;
