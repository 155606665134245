import { useEffect, useState } from "react";
import { Select } from "./Select";
import { TextInput } from "./TextInput";

type Props = {
  initialState?: any;
  form: {
    name: string;
    type?: "text" | "select";
    options?: { label: string; value: string }[];
    default?: string;
    render?: (props: {
      value: string;
      onChange: (newValue) => void;
      label: string;
    }) => JSX.Element;
  }[];
  onChange: (newValue: { [key: string]: string }) => void;
};

export const FormBuilder = ({
  initialState = {},
  form,
  onChange,
}: Props): JSX.Element => {
  const [values, setValues] = useState(
    form.reduce(
      (acc, input) => ({
        ...acc,
        [input.name]: initialState[input.name] ?? input.default ?? "",
      }),
      {}
    )
  );

  useEffect(() => {
    onChange(values);
  }, [onChange, values]);

  return (
    <>
      {form.map((input) => {
        if (input.type == null || input.type === "text") {
          return (
            <TextInput
              key={input.name}
              value={values[input.name]}
              onChange={(value) =>
                setValues({ ...values, [input.name]: value })
              }
              label={input.name}
            />
          );
        }

        if (input.type === "select") {
          return (
            <Select
              key={input.name}
              value={values[input.name]}
              onChange={(selected) =>
                setValues({ ...values, [input.name]: selected })
              }
              options={input.options || []}
            />
          );
        }

        return <>Unsupported input type "{input.type}"</>;
      })}
    </>
  );
};
