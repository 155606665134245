import { useCallback, useEffect, useState } from "react";
import {
  MultiScrapeResult,
  ScrapedItemResult,
  ScrapedItemState,
  SingleScrapeResult,
} from "../types";
import { useApi } from "./useApi";

export type FeedSource = {
  name: string;
  config: { sourceId: number; url: string; name: string };
  loading: boolean; // Loading results
  loadMoreUrl: string | null;
  done: boolean; // No more results to load
  scrapedItemResults: ScrapedItemResult[];
  page: number;
};

const createSourceFromConfig = (config: FeedSource["config"]): FeedSource => {
  return {
    name: config.name,
    config: config,
    loading: false,
    done: false,
    loadMoreUrl: null,
    scrapedItemResults: [],
    page: 0,
  };
};

export const useFeed = ({
  initialSources,
  initialMaxPages = 1,
}: {
  initialSources: FeedSource["config"][];
  initialMaxPages?: number;
}): SourceGateway => {
  const { post } = useApi();
  const [maxPages, setMaxPages] = useState(initialMaxPages);
  const [loadingNextItem, setLoadingNextItem] = useState(false);
  const [loadingItemsForSource, setLoadingItemsForSource] = useState(false);
  const [sources, setSources] = useState<FeedSource[]>(
    initialSources.map((c) => createSourceFromConfig(c))
  );
  const [sourcesToLoad, setSourcesToLoad] = useState<FeedSource[]>(
    initialSources.map((c) => createSourceFromConfig(c))
  );
  const [currentItem, setCurrentItem] = useState<SingleScrapeResult | null>(
    null
  );
  const sourcesWithLoadMore = sources.filter(
    (s) => s.loadMoreUrl != null && s.done === false
  );
  const itemCount = [...sources.map((s) => s.scrapedItemResults)].flat().length;

  const addSources = (newSources: FeedSource[], setter = setSources) => {
    setter((prev) => {
      return [
        ...prev,
        ...newSources.filter((s) => {
          const match = prev.find(
            (p) =>
              p.config.sourceId === s.config.sourceId &&
              p.config.url === s.config.url
          );
          return match == null;
        }),
      ];
    });
  };

  const updateSource = useCallback(
    ({ source }: { source: FeedSource }): void => {
      setSources((prevSources) => [
        ...prevSources.filter(
          (s) =>
            s.config.sourceId !== source.config.sourceId ||
            s.config.url !== source.config.url
        ),
        source,
      ]);
    },
    []
  );

  const loadItem = useCallback(
    async ({ scrapedItem }: { scrapedItem: ScrapedItemResult }) => {
      const { data: item } = await post<
        SingleScrapeResult,
        { sourceId: number; query: string }
      >(`/scrape`, {
        sourceId: scrapedItem.sourceId,
        query: scrapedItem.url,
      });

      return item;
    },
    [post]
  );

  const loadItemsForSource = useCallback(
    async (source: FeedSource) => {
      if (
        loadingItemsForSource ||
        source.done ||
        source.loading ||
        source.scrapedItemResults.length > 0 ||
        source.page >= maxPages
      ) {
        return;
      }
      setLoadingItemsForSource(true);
      source.loading = true;
      updateSource({ source });

      const scrapeResult = await post<
        SingleScrapeResult | MultiScrapeResult,
        { sourceId: number; query: string }
      >(`/scrape`, {
        sourceId: source.config.sourceId,
        query: source.loadMoreUrl ?? source.config.url,
      });

      if (scrapeResult.data.type !== "multiResult") {
        source.done = true;
        updateSource({ source });
        setLoadingItemsForSource(false);
        return;
      }

      const multiScrapeResult =
        scrapeResult.data as unknown as MultiScrapeResult;
      const newItems = multiScrapeResult.items.filter(
        (i) => i.scrapedItemResult.state === ScrapedItemState.LOADED
      );

      source.scrapedItemResults.push(
        ...newItems.map((i) => i.scrapedItemResult)
      );
      source.loadMoreUrl = multiScrapeResult.nextPageSource ?? null;
      source.done = source.loadMoreUrl === null;
      source.loading = false;
      source.page = source.page + 1;
      updateSource({ source });
      setLoadingItemsForSource(false);
    },
    [loadingItemsForSource, maxPages, post, updateSource]
  );

  const toggleSource = ({ source }: { source: FeedSource["config"] }) => {
    const match = sources.find(
      (s) =>
        s.config.sourceId === source.sourceId && s.config.url === source.url
    );

    if (match != null) {
      setSources((oldSources) => [
        ...oldSources.filter(
          (s) =>
            s.config.sourceId !== source.sourceId || s.config.url !== source.url
        ),
      ]);
      return;
    }

    const newSource = createSourceFromConfig(source);
    addSources([newSource]);
    addSources([newSource], setSourcesToLoad);
  };

  const loadNextItem = useCallback(async () => {
    if (loadingNextItem) return;
    setLoadingNextItem(true);
    const source = sources.find((s) => s.scrapedItemResults.length > 0);
    if (source == null) {
      setLoadingNextItem(false);
      return;
    }
    const itemToLoad = source.scrapedItemResults.shift();
    if (itemToLoad == null) {
      setLoadingNextItem(false);
      return;
    }
    const item = await loadItem({ scrapedItem: itemToLoad });
    // Remove this item from other sources
    for (const source of sources) {
      source.scrapedItemResults = source.scrapedItemResults.filter(
        (itemResult) =>
          itemResult.sourceId !== itemToLoad.sourceId ||
          itemResult.url !== itemToLoad.url
      );
      updateSource({ source });
    }
    setCurrentItem(item);
    setLoadingNextItem(false);
  }, [loadItem, loadingNextItem, sources, updateSource]);

  // Load items for the sources one by one with a delay
  useEffect(() => {
    const timeout = setTimeout(async () => {
      // Don't do anything if already loading.
      if (loadingItemsForSource || loadingNextItem) return;

      // If there is a source to load, load it.
      if (sourcesToLoad.length > 0) {
        await loadItemsForSource(sourcesToLoad.slice(0, 1)[0]);
        setSourcesToLoad(sourcesToLoad.slice(1));
        return;
      }

      // If there is a current item, do nothing.
      if (currentItem != null) return;

      // If there is a next item to load, load it.
      if (itemCount > 0) {
        loadNextItem();
        return;
      }

      // If there are no more items to load, load more for existing sources.
      if (sourcesToLoad.length < 1) {
        addSources(sourcesWithLoadMore, setSourcesToLoad);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [
    currentItem,
    itemCount,
    loadItemsForSource,
    loadNextItem,
    loadingItemsForSource,
    loadingNextItem,
    sourcesToLoad,
    sourcesWithLoadMore,
  ]);

  const skipItem = () => {
    setCurrentItem(null);
  };

  const dismissItem = async () => {
    await post("/scrape/dismiss", {
      sourceId: currentItem?.sourceId,
      url: currentItem?.queryUrl,
    });

    skipItem();
  };

  return {
    item: currentItem,
    itemCount,
    done: sources.filter((s) => s.done === false).length === 0,
    doneWithCurrentPages:
      sources.filter(
        (s) => s.scrapedItemResults.length > 0 || s.page < maxPages
      ).length === 0,
    skipItem,
    dismissItem,
    sources,
    sourcesToLoad,
    sourcesDone: sources.filter(
      (s) => s.done || (s.page >= maxPages && s.scrapedItemResults.length === 0)
    ).length,
    toggleSource,
    addPage: () => setMaxPages(maxPages + 1),
    maxPages,
  };
};

interface SourceGateway {
  item: SingleScrapeResult | null;
  itemCount: number;
  done: boolean; // Ran out of available items
  skipItem: () => void;
  dismissItem: () => Promise<void>;
  sources: FeedSource[];
  sourcesToLoad: FeedSource[];
  sourcesDone: number;
  toggleSource: ({ source }: { source: FeedSource["config"] }) => void;
  addPage: () => void;
  doneWithCurrentPages: boolean;
  maxPages: number;
}
