import styled from "@emotion/styled";

type Props = {
  value?: string;
};

export const TextView = ({ value }: Props) => {
  if (value == null) {
    return <Wrapper>(emtpy)</Wrapper>;
  }

  return (
    <Wrapper>
      <p>{value}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  background: lightgray;
`;
