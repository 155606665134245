import styled from "@emotion/styled";
import { Select } from "components/input/Select";
import { FilterDefaults, CustomPropFilter } from "features/custom-props/types";

export const TextFilter = ({ filter }: { filter: CustomPropFilter }) => {
  const { prop, onChange, value = "" } = filter;

  return (
    <Wrapper>
      <span>{prop.name}</span>
      <Select
        onChange={onChange}
        value={value}
        options={[
          { label: "All", value: "" },
          { label: "Set", value: FilterDefaults.SET },
          { label: "Not set", value: FilterDefaults.EMPTY },
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
