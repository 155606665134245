import { useApi } from "hooks/useApi";
import { MultiScrapeResult, SingleScrapeResult } from "types";

export enum ResultType {
  SINGLE = "single",
  MULTI = "multi",
  NO_RESULT = "no-result",
}

export type SingleResult = {
  type: "single";
  result: SingleScrapeResult;
};

export type MultiResult = {
  type: "multi";
  result: MultiScrapeResult;
};

export type NoResult = {
  type: "no-result";
  result: null;
};

export const useScraper = (): ScraperGateway => {
  const { post } = useApi();

  const scrape = async ({ sourceId, query }) => {
    const scrapeResult = await post<
      SingleScrapeResult | MultiScrapeResult,
      { sourceId: number; query: string }
    >(`/scrape`, {
      sourceId,
      query,
    });

    if (
      scrapeResult.data != null &&
      scrapeResult.data.type === "singleResult"
    ) {
      return {
        type: "single",
        result: scrapeResult.data,
      } as SingleResult;
    }

    if (scrapeResult.data != null && scrapeResult.data.type === "multiResult") {
      return {
        type: "multi",
        result: scrapeResult.data,
      } as MultiResult;
    }

    return {
      type: "no-result",
      result: null,
    } as NoResult;
  };

  return {
    scrape,
    isSingleResult: (
      result: SingleResult | MultiResult | NoResult
    ): result is SingleResult => {
      return (result as SingleResult).result.attributes.cover.value != null;
    },
  };
};

interface ScraperGateway {
  scrape: ({
    sourceId,
    query,
  }: {
    sourceId: number;
    query: string;
  }) => Promise<SingleResult | MultiResult | NoResult>;
  isSingleResult: (
    result: SingleResult | MultiResult | NoResult
  ) => result is SingleResult;
}
