export const Spacer = ({
  w = 1,
  h = 1,
}: {
  w?: number | string;
  h?: number | string;
}) => {
  return (
    <div
      style={{
        width: typeof w === "string" ? w : w + "px",
        height: typeof h === "string" ? h : h + "px",
      }}
    />
  );
};
