import { useAuth0 } from "@auth0/auth0-react";
import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

type RequireAuthenticationProps = {
  children: ReactElement;
};

const LOCALSTORAGE_REDIRECT_KEY = "redirectAfterLogin";

// A wrapper for a <Route> element that redirects to the login
// screen if you're not yet authenticated.
export const RequireAuthentication = ({
  children,
}: RequireAuthenticationProps): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  if (!isAuthenticated) {
    window.localStorage.setItem(
      LOCALSTORAGE_REDIRECT_KEY,
      location.pathname + location.search
    );
    return <Navigate to="/login" />;
  }

  const redirectAfterLogin = window.localStorage.getItem(
    LOCALSTORAGE_REDIRECT_KEY
  );

  if (redirectAfterLogin != null) {
    if (location.pathname + location.search === redirectAfterLogin) {
      window.localStorage.removeItem(LOCALSTORAGE_REDIRECT_KEY);
    }

    return <Navigate to={redirectAfterLogin} />;
  }

  return children;
};
