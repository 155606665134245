export const isJsonString = (string: any): boolean => {
  try {
    JSON.parse(string);
  } catch {
    return false;
  }

  return true;
};

export const isValidHttpUrl = (value: string): boolean => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const wait = async (ms: number = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const extractKeyFromObject = <K extends string | number | symbol, V>(
  object: Record<K, V>,
  key: K
): {
  originalObject: object;
  extracted: {
    key: K;
    value: V | undefined;
  };
  restObject: Record<K, V>;
} => {
  const restObject = Object.entries(object)
    .filter(([k]) => k !== key)
    .reduce((prev, [key, value]) => {
      prev[key] = value;
      return prev;
    }, {} as Record<K, V>);

  return {
    originalObject: object,
    extracted: {
      key,
      value: object[key],
    },
    restObject,
  };
};
