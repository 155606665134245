import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "components/input/Switch";
import { Alert } from "components/ui/Alert";
import { useApi } from "hooks/useApi";
import { useCollection } from "hooks/useCollection";
import { Item, ScrapeValue, SingleScrapeResult } from "types";
import { ScrapedTaxonomiesView } from "./scraped-taxonomies-view";
import { CustomProp } from "features/custom-props";

export const SingleScrapeResultView = ({
  result,
  onSelect,
  selectedAttributeNames,
  hideTaxonomies = false,
}: {
  result: SingleScrapeResult;
  onSelect?: (attributeName: string) => void;
  selectedAttributeNames?: string[];
  hideTaxonomies?: boolean;
}) => {
  const { collection } = useCollection();
  const { post } = useApi();
  const [duplicateItem, setDuplicateItem] = useState<{
    item: Item | null;
    sourceId: number;
    url: string;
  } | null>(null);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (
        duplicateItem != null &&
        String(duplicateItem.sourceId) === String(result.sourceId) &&
        duplicateItem.url === result.responseUrl
      )
        return;

      const { data: item } = await post<Item | null, any>(
        `/items/findDuplicate`,
        {
          usedSources: [{ sourceId: result.sourceId, url: result.responseUrl }],
          collectionId: collection.id,
        }
      );

      if (item == null) {
        setDuplicateItem({
          item: null,
          sourceId: result.sourceId,
          url: result.responseUrl,
        });
        return;
      }

      setDuplicateItem({
        sourceId: result.sourceId,
        url: result.responseUrl,
        item,
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [collection.id, duplicateItem, post, result.responseUrl, result.sourceId]);

  const customPropResults = Object.entries(result.attributes)
    .filter(([key]) => {
      if (key.startsWith("customProp-") === false) return false;

      const prop = collection.customProps.find(
        (p) => p.id === Number(key.replace("customProp-", ""))
      );

      return prop != null;
    })
    .map(([key, result]) => {
      return {
        prop: collection.customProps.find(
          (p) => p.id === Number(key.replace("customProp-", ""))
        ),
        result,
      };
    }) as { prop: CustomProp; result: ScrapeValue }[];

  return (
    <Wrapper>
      {duplicateItem?.item != null && (
        <div style={{ marginBottom: 12 }}>
          <Alert>
            Duplicate of{" "}
            <Link
              to={`/collections/${collection.id}/items/${duplicateItem.item.id}`}
            >
              {duplicateItem.item.title}
            </Link>
          </Alert>
        </div>
      )}

      <h3>
        Title{" "}
        {onSelect != null && (
          <Switch
            value={selectedAttributeNames?.includes("title") ?? false}
            label={""}
            onChange={() => onSelect("title")}
          />
        )}
      </h3>
      <p>{result.attributes.title.value}</p>

      <a
        href={result.responseUrl}
        target="_blank"
        rel="noreferrer"
        style={{ display: "block", marginTop: 12, marginBottom: 12 }}
      >
        {result.responseUrl}
      </a>

      <h3>
        Cover{" "}
        {onSelect != null && (
          <Switch
            value={selectedAttributeNames?.includes("cover") ?? false}
            label={""}
            onChange={() => onSelect("cover")}
          />
        )}
      </h3>
      <img
        src={result.attributes.cover.value}
        alt={result.attributes.title.value}
      />

      {customPropResults.length > 0 &&
        customPropResults.map((customPropResult) => (
          <div key={customPropResult.prop.id}>
            <h3>
              {customPropResult.prop.name}{" "}
              {onSelect != null && (
                <Switch
                  value={
                    selectedAttributeNames?.includes(
                      "customProp-" + customPropResult.prop.id
                    ) ?? false
                  }
                  label={""}
                  onChange={() =>
                    onSelect("customProp-" + customPropResult.prop.id)
                  }
                />
              )}
            </h3>
            <p>
              {customPropResult.result.value} (
              {customPropResult.result.rawValue})
            </p>
          </div>
        ))}

      {hideTaxonomies === false && result.taxonomies.length > 0 && (
        <ScrapedTaxonomiesView taxonomies={result.taxonomies} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px 0;

  img {
    max-width: 100%;
  }

  h3,
  p {
    word-wrap: break-word;
  }
`;
