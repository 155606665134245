import styled from "@emotion/styled";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";

type SelectOption = {
  label: string;
  value: string;
};

type Props = {
  value: string;
  onChange: (newValue: string) => void;
  options: SelectOption[];
  label?: string;
  styles?: object;
};

export const Select = ({
  label,
  value,
  onChange,
  options,
  styles = {},
}: Props): JSX.Element => {
  return (
    <Wrapper>
      {label}
      <MuiSelect
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
        sx={styles}
        fullWidth
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
