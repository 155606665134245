import styled from "@emotion/styled";
import { useState } from "react";
import { useCollection } from "../../hooks/useCollection";
import { styleConfig } from "../../styleConfig";
import { SingleScrapeResult } from "../../types";
import { Button } from "../input/Button";
import { Select } from "../input/Select";
import { Scraper } from "./Scraper";
import { SingleScrapeResultView } from "features/scraper";

type Props = {
  onCancel: () => void;
  onUse: (result: SingleScrapeResult) => void;
  initialQuery?: { url: string; sourceId: number };
  hideTaxonomies?: boolean;
};

export const ScraperPopup = ({
  onCancel,
  onUse,
  initialQuery,
  hideTaxonomies = false,
}: Props) => {
  const { collection } = useCollection();
  const [sourceId, setSourceId] = useState<string | null>(
    initialQuery != null
      ? String(initialQuery.sourceId)
      : collection.sources.length > 0
      ? String(collection.sources[0].id)
      : null
  );
  const source = collection.sources.find(
    (source) => source.id === Number(sourceId)
  );
  const [result, setResult] = useState<SingleScrapeResult | null>(null);
  const [selectingUse, setSelectingUse] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);

  const parseResult = (result: SingleScrapeResult) => {
    setResult(result);
    setSelectedAttributes(Object.keys(result?.attributes ?? {}));
  };

  const use = () => {
    if (result == null) return;

    const data = {
      ...result,
      attributes: Object.entries(result.attributes)
        .filter(([key, value]) => selectedAttributes.includes(key))
        .reduce((prev, [key, value]) => {
          prev[key] = value;
          return prev;
        }, {}),
    };

    onUse(data);
  };

  return (
    <ScraperWrapper
      onClick={function (e) {
        if (e.target !== e.currentTarget) return;
        onCancel();
      }}
    >
      <ScraperContent>
        <header>
          <Select
            onChange={setSourceId}
            options={collection.sources.map((source) => ({
              label: source.name,
              value: String(source.id),
            }))}
            value={sourceId ?? ""}
            label="Source"
          />
        </header>
        <main>
          {source != null && selectingUse === false && (
            <Scraper
              source={source}
              onSingleResult={parseResult}
              hideTaxonomies={hideTaxonomies}
              initialQuery={initialQuery != null ? initialQuery.url : undefined}
            />
          )}

          {result != null && selectingUse === true && (
            <>
              <SingleScrapeResultView
                result={result}
                selectedAttributeNames={selectedAttributes}
                hideTaxonomies={hideTaxonomies}
                onSelect={(attributeName) => {
                  if (selectedAttributes.includes(attributeName)) {
                    setSelectedAttributes([
                      ...selectedAttributes.filter((a) => a !== attributeName),
                    ]);
                  } else {
                    setSelectedAttributes([
                      ...selectedAttributes,
                      attributeName,
                    ]);
                  }
                }}
              />
            </>
          )}
        </main>
        <footer>
          {selectingUse === true && (
            <>
              <Button onClick={() => setSelectingUse(false)}>Back</Button>
              <Button onClick={use}>Use</Button>
            </>
          )}

          {selectingUse === false && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                disabled={result == null}
                onClick={() => setSelectingUse(true)}
              >
                Select attributes
              </Button>
              <Button disabled={result == null} onClick={use}>
                Use everything
              </Button>
            </>
          )}
        </footer>
      </ScraperContent>
    </ScraperWrapper>
  );
};

const ScraperWrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 48px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1300;
  isolation: isolate;

  @media (max-width: ${styleConfig.breakpoints.mobile}px) {
    padding: 12px;
  }
`;

const ScraperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 12px;
  z-index: 0;

  header {
    padding: 12px;
    border-bottom: 1px solid gray;
  }

  main {
    flex: 1;
    padding: 12px;
    overflow: auto;
  }

  footer {
    display: flex;
    justify-content: end;
    padding: 12px;
    border-top: 1px solid gray;
  }
`;
